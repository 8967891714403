.vnc_viewer {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow-x: hidden;
  flex-direction: column;
  gap: 20px;
  background-color: white;
}

.vnc-viewer {
  position: relative;
}

.no-margin {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.display-container {
  display: flex;
  width: 100%;
  height: calc(100vh - 85px);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.full-screen {
  height: 100%;
  width: 100%;
  background-color: white;
}

.fullHeight {
  height: 100vh;
}

.info {
  width: 100%;
  text-align: center;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.state_session {
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.row {
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.session {
  height: 100% !important;
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#view {
  padding: 0 !important;
}