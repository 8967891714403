.verbs {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin: 0 !important;
  }
  & > label {
    font-size: 15px;
    font-weight: 500;
  }
  .ant-checkbox-group {
    flex-wrap: wrap !important;
    gap: 5px !important;
  }

  .label {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
    margin-bottom: 8px;
  }
}
.Actions {
  .ant-checkbox-group {
    flex-wrap: wrap !important;
    gap: 10px !important;
  }
}

.select {
  margin-bottom: 20px;
}

.user_group_modal {
  margin-right: 10px;

  .dynamic_fields {
    padding: 18px !important;
    margin-top: 22px;
    border: 1px solid #d0d5dd;
    padding-bottom: 15px;
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 9px;

    .add_remove_btn {
      display: flex;
      justify-content: flex-end;
      flex-direction: row-reverse;
      align-items: center;
      gap: 8px;

      button {
        width: 28px;
        height: 28px;
        // background: #0188f7;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .dynamic-fields-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 12px;
      background: #f2fafe;
      border-radius: 8px;
      padding: 9px 10px;
      margin-bottom: 18px;

      label {
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        color: #344054;
        margin: 0;
      }
    }

    .ant-checkbox {
      width: 16px;
      height: 16px;
      background: #ffffff;
      border: 1px solid #e4e7ec;
      border-radius: 50%;
      margin-left: 12px;

      .ant-checkbox-input,
      .ant-checkbox-inner {
        display: none !important;
      }
    }

    .ant-checkbox-input {
      display: none !important;
    }

    .ant-checkbox-wrapper {
      flex-direction: row-reverse;
      // gap: 12px;
      padding: 10px 14px;
      height: 44px;
      border-radius: 8px;
      background: #ffffff;
      border: 1px solid #d0d5dd;
      color: #475467;

      &:hover {
        background-color: #e5f4fd;
        border: 1px solid #a4ddf8;
        color: #475467;

        .ant-checkbox {
          border-radius: 50% !important;
          background-color: #e5f4fd !important;
          border: 1px solid #25c4f4 !important;
          content: "";
          &::after {
            display: none !important;
          }
        }
      }

      span {
        padding: 0;
      }
    }

    .ant-checkbox-wrapper-checked {
      border: 1px solid #21afda;
      background-color: #25c4f4;
      color: white;

      &:hover {
        border: 1px solid #21afda;
        background-color: #25c4f4;
        color: white;

        .ant-checkbox {
          background: #25c4f4 !important;
          border: 3px solid #e5f4fd !important;
        }
      }

      .ant-checkbox {
        background: #25c4f4;
        border: 3px solid #e5f4fd;
      }
    }
  }

  .action_form {
    overflow: hidden;
    margin-top: 28px;
  }
  .ant-checkbox-wrapper {
    display: flex !important;

    .ant-checkbox-inner {
      display: flex !important;
      align-items: center;
      justify-content: center;
    }
  }
  .user_group_modal .ant-checkbox-wrapper {
    flex-wrap: wrap;
  }
}
