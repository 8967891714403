.resource {
  width: 50%;
  max-width: 360px;
  .resource-header {
    display: flex;
    align-items: center;
    padding-top: 11px;
    p:first-of-type {
      flex: 1;
      color: var(--font, #344054);
      font-size: 18.241px;
      font-style: normal;
      font-weight: 400;
      line-height: 33.166px;
      white-space: nowrap;
      margin-left: 23px;
    }
    p:last-of-type {
      color: var(--font, #344054);
      font-size: 19.372px;
      font-style: normal;
      font-weight: 600;
      line-height: 33.166px;
      span {
        color: var(--font, #344054);
        font-size: 12.09px;
        font-style: normal;
        font-weight: 300;
        line-height: 29.259px;
      }
    }
  }
}
.resource-progress {
  .ant-progress-line {
    margin: 0;
  }
  .ant-progress-bg {
    background: linear-gradient(270deg, #01d5eb 5.22%, #0166fc 102.28%) !important;
  }
}

.resource.small {
  .resource-header {
    svg {
      width: 21.534px;
      height: 21.534px;
    }
    p:first-of-type {
      font-size: 11.844px;
      font-style: normal;
      font-weight: 400;
      line-height: 21.534px;
    }
    p:last-of-type {
      color: var(--font, #344054);
      font-size: 12.578px;
      font-style: normal;
      font-weight: 600;
      line-height: 21.534px;
    }
  }
}
