.lti-auth {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .lti-loading {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 130px;

        span {
            font-size: 15px;
            font-weight: 500;
        }

    }
}