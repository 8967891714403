.ProfileV2-page-col-2-form-general {
  width: 100%;

  .ProfileV2-page-col-2-form-input-width {
    width: 100%;

    .ProfileV2-page-col-2-label {
      color: #344054;
      font-size: 12.556px;
      font-style: normal;
      font-weight: 500;
      line-height: 17.938px; /* 142.857% */
    }

    .personal-info-input {
      padding: 12px !important;
      color: #344054;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 21.525px; /* 143.501% */
    }

    .personal-info-input-disabled {
      color: #344054;
      border-radius: 7.175px;
      border: 0.897px solid #d0d5dd;
      background: #eff3fb;
    }
  }

  .ProfileV2-page-col-2-form-btn {
    width: 100%;
    margin-top: 12px;

    .ProfileV2-page-col-2-form-btn-confirm {
      cursor: pointer;
      width: 180px;
      height: 44px;
      background: #0188f7;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      color: #fff;
      transition: all 300ms ease;
      border: 1px solid #0188f7;
      box-shadow: 0px 0.89688px 1.79376px 0px rgba(16, 24, 40, 0.05);

      &:hover {
        background-color: #0b7ad5;
        color: #ffffff;
      }
    }
  }
}
