.btn_plan {
  cursor: pointer;
  display: flex;
  width: 200.141px;
  padding: 9.407px 16.933px;
  justify-content: center;
  align-items: center;
  gap: 7.526px;
  color: var(--prim-blue, #0188f7);
  font-size: 15.051px;
  font-weight: 600;
  line-height: 22.577px;
  background-color: transparent;
  border-radius: 8px;
  border: 0.941px solid var(--prim-blue, #0188f7);
  background: var(--base-white, #fff);
  box-shadow: 0px 0.940710186958313px 1.881420373916626px 0px rgba(16, 24, 40, 0.05);

  &:hover {
    background: var(--primary-colors-primary-100, #e5f4fd);
  }
}
