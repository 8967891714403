.invite-users-form {
  .input-container {
    position: relative;
    &:hover {
      .input-menu {
        opacity: 1;
        visibility: visible;
      }
      .input_antd {
        padding-right: 30px;
      }
    }

    .input-menu {
      position: absolute;
      right: 8px;
      z-index: 2;
      opacity: 0;
      visibility: hidden;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: all 0.2s;
      -webkit-transition: all 0.2s;
      -moz-transition: all 0.2s;
      -ms-transition: all 0.2s;
      -o-transition: all 0.2s;
    }
  }
  .plus-btn-container {
    color: #0188f7;
    display: flex;
    align-items: center;
    cursor: pointer;
    column-gap: 5px;
    width: fit-content;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
  .error {
    .input_antd {
      border: 1px solid red;
    }
  }
}
