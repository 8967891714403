.feedback-modal {
  .back {
    position: absolute;
    left: 25px;
    top: 15px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    &:hover {
      background-color: #eee;
    }
    svg {
      display: block;
      width: 16px;
      height: 16px;
      margin: 12px;
      fill: #a4ddf8;
    }
  }
  .feedback-container {
    .feedback-type {
      display: flex;
      align-items: center;
      column-gap: 15px;
      padding: 18px 15px;
      border-radius: 8px;
      transition: all 0.1s linear;
      cursor: pointer;
      &:hover {
        background-color: #f9fafb;
      }
      &-icon {
        width: 51px;
        height: 51px;
        border: 1px solid #f2f5f7;
        border-radius: 50%;
        background-color: #fff;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: 15px;
        }
      }
      &-content {
        p:first-child {
          font-size: 14px;
          font-weight: 500;
          color: #232e3a;
          margin-bottom: 3px;
        }
        p:last-child {
          font-size: 13px;
          color: #6080a0;
        }
      }
    }
    .feedback-type:not(:last-child) {
      margin-bottom: 15px;
    }
  }
}
