.form_user,
.form {
  padding-right: 4px;
  label {
    font-weight: 400;
    font-size: 14px;
    color: #344054;
    margin-bottom: 3px;
    display: block;
  }
  .action_form {
    float: right;
    margin-top: 35px;
    display: flex;
    justify-content: center;
    gap: 12px;
    width: 100%;

    button {
      border: 1px solid #d0d5dd;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      height: 42px;
      padding: 10px 18px;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      background: #475467;
      color: #ffff;
      cursor: pointer;
    }
    & :first-child {
      background-color: #ffff;
      color: #344054;
      &:hover {
        background-color: #fafafa;
      }
    }

    .dashboard-cancel-btn {
      width: 210px;
      height: 44px;
      background: #ffffff;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      color: #0188f7;
      transition: all 300ms ease;
      border: 1px solid #0188f7;

      &:hover {
        background-color: #0188f7;
        color: #ffffff;
      }
    }

    .dashboard-confirm-btn {
      width: 210px;
      height: 44px;
      background: #0188f7;
      border: 1px solid #0188f7;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      color: #ffffff;
      transition: all 300ms ease;

      &:hover {
        background: #0188f7;
        filter: brightness(90%);
      }
    }
  }
  .active_user {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 15px;

    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #0188f7;
    }
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #344054;
    }
  }

  .ant-select-selection-item {
    align-self: center;
  }
}

.country-info-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
  border: 1px solid #d9d9d9;
  padding-left: 12px;
  border-radius: 8px;

  .flag {
    font-size: 22px;
    padding-right: 8px;
    border-right: 1px solid #d9d9d9;
  }

  .ant-select {
    margin-bottom: 0;

    .ant-select-selector {
      border: 0 !important;
      box-shadow: none !important;
    }
  }
}
