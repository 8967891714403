.user_drawer {
  .ant-drawer-body {
    padding: 34px !important;
    margin: 0 !important;
  }
  .head_user_drawer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    .user_avatar {
      display: flex;
      align-items: center;
      gap: 10px;
      & > span {
        .title {
          font-weight: 600;
          font-size: 16px;
          color: #374957;
        }
        .email {
          font-weight: 400;
          font-size: 13px;
          color: #374957;
        }
      }
    }
    .close_ion {
      border: 1px solid #ececec;
      border-radius: 50%;
      width: 38px;
      height: 38px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
  .user_content {
    .view_tabs {
      margin-bottom: 10px;
      .ant-tabs-nav {
        padding: 0;
        margin-left: -5px !important;
      }
    }

    .user_details {
      .key_value {
        border-bottom: 1px solid #ececec;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 60px;

        .key {
          font-weight: 400;
          font-size: 14px;
          color: #858585;
        }
        .value {
          font-weight: 400;
          font-size: 14px;
          color: #282e49;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .active {
          font-weight: 400;
          font-size: 12px;
          color: #027a48;
          width: 83px;
          height: 28px;
          background: #d1fadf;
          border-radius: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .inactive {
          font-weight: 400;
          font-size: 12px;
          color: red;
          width: 83px;
          height: 28px;
          background: #fee4e2;
          border-radius: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .title_list {
    height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    color: #858585;
  }
  .title_name_list {
    height: 54px;
    background: #f2f4f7;
    border-width: 0px 0px 1px 1px;
    border-style: solid;
    border-color: #e4e7ec;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 18px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;

    color: #101828;
  }
  .ant-list {
    // ant-list-split ant-list-something-after-last-item
    margin-bottom: 6px;
    .ant-spin-nested-loading {
      border: 1px solid #e4e7ec;
      border-top: none;
      max-height: calc(100vh - 369px - 80px);
      overflow: auto;
      .ant-spin-container {
        .ant-list-items {
          .ant-list-item {
            height: 60px;
            .ant-list-item-meta {
              justify-content: center;
              align-items: center;
              // .ant-list-item-meta-avatar {
              // }
              .ant-list-item-meta-content {
                h4 {
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: #101828;
                  margin-bottom: -1px;
                }
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: #475467;
              }
            }
          }
        }
      }
    }
  }
}
