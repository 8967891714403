.pay_method {
  .first_section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 28px;

    @media only screen and (max-width: 602px) {
      flex-wrap: wrap;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
    }
    .title {
      color: var(--font, #344054);
      font-size: 18px;
      display: flex;
      align-items: center;
      gap: 16px;
      font-weight: 500;
      line-height: 28px;
      margin-bottom: 7px;
    }
    .subtitle {
      color: var(--font, #344054);
      font-size: 14px;
      line-height: 20px;
      // text-overflow: ellipsis;
      // overflow: hidden;
      // white-space: nowrap;
    }
    .new_pay_method {
      color: var(--font, #344054);
      font-size: 15px;
      line-height: 28px;
      display: flex;
      padding: 10px;
      align-items: flex-start;
      gap: 10px;
      border-radius: 8px;
      border: 1px solid var(--stroke, #e4e7ec);
      background-color: transparent;
      cursor: pointer;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.method {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 17px 10px;
  border-radius: 8px;
  border: 1px solid var(--stroke, #e4e7ec);
  margin-bottom: 10px;

  @media only screen and (max-width: 602px) {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
  .left {
    display: flex;
    gap: 10px;
    @media only screen and (max-width: 482px) {
      flex-wrap: wrap;
      flex-direction: column;
    }

    .visa {
      width: 100px;
      height: 68px;
      @media only screen and (max-width: 602px) {
        // max-width: 100px;
        // height: 60px;
        object-fit: fill;
      }
    }

    .content {
      .cart_number {
        color: var(--font, #344054);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
      .expired_at {
        color: #7a818c;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        margin-bottom: 8px;
      }
      .email_s {
        display: flex;
        gap: 8px;
        color: #7a818c;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;

        .email {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
        }
      }
    }
  }
  .right {
    button {
      max-width: 100px !important;
    }
  }
}
