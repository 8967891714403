.section_categories {
  // bottom: 70px;

  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 140px;
  @media only screen and (max-height: 500px) {
    height: 110px;
  }
  position: absolute;
  bottom: 0;

  .desktop_categories {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;
    gap: 22px;
    width: fit-content;
    // bottom: 70px;
    // height: 50px;
    background: #fafafa;
    border-radius: 8px;
    margin: 0 30px;
    flex-wrap: wrap;

    @media only screen and (max-width: 1300px) {
      gap: 12px;
      justify-content: center;
    }
    @media only screen and (max-width: 1800px) {
      bottom: 40px;
    }
    @media only screen and (max-width: 1300px) {
      bottom: 20px;
    }
    button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px;
      background: none;
      border-radius: 8px;
      border: none;
      cursor: pointer;
      font-weight: 400;
      font-size: 14px;
      color: #667085;
      @media only screen and (max-width: 1300px) {
        font-size: 11px;
        padding: 7px;
        border-radius: 4px;
      }
      &:focus {
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
        background: #fafafa;
      }
    }
  }

  .ant-segmented {
    padding: 5px;
    margin: 0 30px;
    background-color: #e5f4fd;
    .ant-segmented-group {
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 12px;
      .ant-segmented-item {
        .ant-segmented-item-label {
          color: #667085;
          font-weight: 400;
          font-size: 14px;
        }
      }
    }
  }
}
