@use "./components/Modals/GroupModal";
@use "./components/GroupDetails";
@use "./components/UserGroupForm/UserGroupForm";

.view_container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .levels_table__row {
    & > .title_column {
      & > .title {
        font-size: 14px;
        margin-left: 8px;
        white-space: nowrap;

        @media only screen and (max-width: 1800px) {
          font-weight: 400;
          font-size: 13px;
        }
        & > .email {
          font-weight: 400;
          font-size: 13px;
          color: #475467;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 300px;

          @media only screen and (max-width: 1800px) {
            font-weight: 400;
            font-size: 12px;
            max-width: 250px;
          }
          @media only screen and (max-width: 1500px) {
            max-width: 160px;
          }
          @media only screen and (max-width: 1300px) {
            font-weight: 400;
            font-size: 12px;
            max-width: 120px;
          }
          @media only screen and (max-width: 800px) {
            font-weight: 400;
            font-size: 12px;
            max-width: 93px;
          }
        }
      }
      .user_title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100%;
        // @media only screen and (max-width: 1800px) {
        //   max-width: 250px;
        // }
        // @media only screen and (max-width: 1500px) {
        //   max-width: 160px;
        // }
        // @media only screen and (max-width: 1300px) {
        //   max-width: 120px;
        // }
        // @media only screen and (max-width: 800px) {
        //   max-width: 93px;
        // }
      }
    }
    & > .status_column {
      .user__active {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #d1fadf;
        border-radius: 16px;
        height: 22px;
        min-width: 58px;
        font-weight: 400;
        font-size: 11px;
        line-height: 18px;
        gap: 5px;
        color: #027a48;
        padding: 0 5px;
        p {
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: #027a48;
        }
      }
      .user__inactive {
        padding: 0 5px;

        display: flex;
        align-items: center;
        justify-content: center;
        background: #fee4e2;
        border-radius: 16px;
        height: 22px;
        min-width: 58px;
        font-weight: 400;
        font-size: 11px;
        line-height: 18px;
        gap: 5px;
        color: #f04438;
        p {
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: #f04438;
        }
      }
    }
    & > .user_group_column,
    & > .image_group_column,
    & > .status_column {
      display: flex;
      gap: 0px;
      flex-wrap: wrap;
      row-gap: 4px;
    }
    & > .user_group_column,
    & > .date_column,
    & > .image_group_column {
      font-weight: 400;
      font-size: 13px;
      color: #667085;
    }
    & > .date_column {
      .date {
        font-weight: 300;
        font-size: 13px;
        color: #667085;
      }
    }
  }
  .header_column__check_and_title {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}

//responsive drawer width
.ant-drawer-content-wrapper {
  @media only screen and (max-width: 1500px) {
    width: 35% !important;
  }
  @media only screen and (max-width: 1300px) {
    width: 45% !important;
  }
  @media only screen and (max-width: 1100px) {
    width: 50% !important;
  }
  @media only screen and (max-width: 900px) {
    width: 60% !important;
  }
  @media only screen and (max-width: 800px) {
    width: 90% !important;
  }
}

.table_action {
  min-width: 120px;
  max-width: 120px;
}
.user_table_name {
  max-width: calc((100% - (80px + 70px + 25px + 120px + 38px + 34px * 4)) / 2);
  width: calc((100% - (80px + 70px + 25px + 120px + 38px + 34px * 4)) / 2) !important;
  white-space: nowrap;
}
.table_status {
  min-width: 80px;
  max-width: 80px;
}
.table_date {
  min-width: 70px;
  max-width: 70px;
}
.user_number {
  min-width: 80px;
  max-width: 80px;
}

.full_name {
  @media only screen and (max-width: 1600px) {
    min-width: 120px;
    max-width: 120px;
  }
  // min-width: 90px;
  // max-width: 90px;
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
  }
}
// .image_group {
//   min-width: 80px;
//   max-width: 80px;
// }

.user_group_tag {
  max-width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100%;
  list-style-type: none !important;
}

.no_data {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90%;
}

.user-group-default-roles-action {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border: 1px solid #ffffff;
  border-radius: 4px;
  transition: all 300ms ease;
  cursor: pointer;
  & > svg {
    fill: #aeaeae;
    width: 18px;
    height: 18px;
  }
  &:hover {
    border: 1px solid #e7e7e7;
  }
}
