.slider-antd {
  .slider-label {
    color: var(--Gray-700, #344054);
    font-size: 11.812px;
    font-style: normal;
    font-weight: 500;
    line-height: 16.874px; /* 142.857% */

    display: flex;
    align-items: center;
    gap: 10px;
  }
  .ant-slider-mark {
    top: -26px;
  }
  .ant-slider-with-marks {
    bottom: -23px;
  }
  .slider-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .ant-slider {
      width: 80%;
    }
    .active-value {
      color: var(--font, #344054);
      font-size: 14.805px;
      font-style: normal;
      font-weight: 500;
      line-height: 21.149px;
      border-radius: 8.46px;
      border: 1.057px solid var(--stroke, #e4e7ec);
      width: 80px;
      padding: 6px;
      text-align: center;
    }
  }
}
