.success-payment {
  & > div {
    text-align: center;
    img {
      width: 200px;
      height: 200px;
    }
    p {
      margin-top: 25px;
      font-size: 20px;
      font-weight: 700px;
      color: #2bc48a;
    }
    p:last-child {
      font-size: 14px;
      font-weight: 400;
      color: #969696b8;
      margin-top: 0;
    }
    .action_confirm {
      button {
        border-radius: 7.526px;
        background: var(--prim-blue, #0188f7);
        box-shadow: 0px 0.940710186958313px 1.881420373916626px 0px rgba(16, 24, 40, 0.05);
        color: #fff;
        outline: none;
        border: none;
        font-size: 16px;
        padding: 12px 25px;
        cursor: pointer;
      }
    }
    .action_confirm_disabled {
      button {
        border-radius: 7.526px;
        // background: var(--prim-blue, #0188f7);
        box-shadow: 0px 0.940710186958313px 1.881420373916626px 0px rgba(16, 24, 40, 0.05);
        color: #fff;
        outline: none;
        border: none;
        font-size: 16px;
        padding: 12px 25px;
        color: #b3b3b3bb;
        cursor: not-allowed;
      }
    }
    .invalid-status {
      color: rgb(230, 152, 80);
    }
  }
}
