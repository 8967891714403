.header {
  z-index: 99;
  box-shadow: 0px 2px 19px rgba(205, 236, 236, 0.54);
  display: flex;
  height: 85px !important;
  padding: 21px 30px;
  width: 100% !important;
  justify-content: space-between;
  align-items: center;
  column-gap: 41px;
  background-color: #f8ffff;

  @media only screen and (max-width: 1089px) {
    padding: 12px 20px;
  }

  & > .header__group {
    display: flex;
    gap: 18px;
    align-items: center;
    cursor: pointer;
    @media only screen and (max-width: 1089px) {
      // flex-grow: 1;
      justify-content: space-between;
    }
    .search_header {
      width: 320px;
    }
  }
}
.header_main_items {
  display: flex !important;
  align-items: center;
  width: 100%;
  gap: 25px !important;
  justify-content: flex-end;
}

.header_admin {
  display: flex;
  padding: 16px 30px;
  width: 100%;
  // height: 60px;
  // border-bottom: 1px solid #dadada;
  border-bottom: 1px solid #f1f1f1;

  justify-content: space-between;
  background-color: white !important;
  @media only screen and (max-width: 1089px) {
    padding: 12px 20px;
  }

  & > .header__group {
    display: flex;
    gap: 18px;
    align-items: center;
    @media only screen and (max-width: 1089px) {
      flex-grow: 1;
      justify-content: space-between;
    }

    .admin_page_title {
      font-weight: 500;
      font-size: 18px;
      color: #667085;
    }
  }
}

#hideHeader {
  top: -85px;
}

@media only screen and (max-width: 425px) {
  .header {
    .header__group__logo {
      display: none;
    }
  }
}

.navbar-flag-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;

  .navbar-flag {
    width: 22px;
  }
}

.navbar-dropdown-cursor {
  cursor: pointer;
}
