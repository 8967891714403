.profile-page-content-col-1-avatar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;

  .ProfileV2-page-photo-border {
    padding: 8px;
    border-radius: 50%;
    border: 1px dashed rgba(145, 158, 171, 0.2);
  }

  .ProfileV2-page-photo {
    cursor: pointer;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    display: flex;

    .ProfileV2-page-photo-hover {
      display: none;
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.45);
      border-radius: 50%;

      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 6px;

      .ProfileV2-page-photo-trash-icon {
        position: absolute;
        top: 50px;
        right: 50px;
      }

      .ProfileV2-page-photo-hover-title {
        line-height: 1.5;
        font-size: 0.75rem;
        font-weight: 400;
        color: #fff;
        text-align: center;
        font-weight: 600;
      }
    }

    &:hover {
      .ProfileV2-page-photo-hover {
        display: flex;
      }
    }
  }

  .ProfileV2-page-photo-description {
    width: 70%;
    line-height: 1.5;
    font-size: 0.75rem;
    font-weight: 400;
    color: rgb(145, 158, 171);
    text-align: center;
  }

  .ProfileV2-page-photo-title {
    line-height: 1.57143;
    font-size: 0.875rem;
    font-weight: 400;
  }
}
