.active_desktop-disabled {
  filter: grayscale(0.8);
}

.active_desktop {
  border: 1px solid #e4e7ec;
  border-radius: 8px;
  padding: 5px;
  display: flex;
  height: 52px;
  width: 206px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: #ffff;
  border: 1px solid #e8f2ff;
  box-shadow: 0px 4px 7px rgba(204, 231, 248, 0.33);
  border-radius: 10px;

  .desktop_left {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-right: 8px;
    &-icon {
      width: 38px;
      height: 42px;
      background: #fafafa;
      border-radius: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 22px;
        height: 22px;
      }
    }
    .desktop_name {
      font-weight: 500;
      font-size: 14px;
      color: #101828;
      white-space: nowrap;
      width: 113px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .desktop_time {
      font-weight: 400;
      font-size: 12px;
      color: #667085;
      display: flex;
      align-items: center;
      column-gap: 4px;
      img {
        width: 18px;
        height: 18px;
      }
    }
  }
  .desktop_right {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    img {
      cursor: pointer;
    }
    button {
      border: none;
      background-color: none;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}

._active {
  background: #0188f7;
  border: 1px solid #e8f2ff;
  box-shadow: 0px 4px 7px rgba(204, 231, 248, 0.33);
  border-radius: 10px;
  p {
    color: white !important;
    img {
      filter: invert(79%) sepia(50%) saturate(342%) hue-rotate(169deg) brightness(99%) contrast(97%);
    }
  }
}

.delete-model-icon {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_delete_multiple {
  background-color: rgba(110, 119, 139, 0.9);

  .ant-modal-confirm-title {
    text-wrap: nowrap;
  }
}
