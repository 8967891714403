.menu {
  display: flex;

  .avatar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
    cursor: pointer;
  }

  .profile__avatar {
    cursor: pointer !important;
  }
}


.notification-title {
  font-weight: 600;
}

.notification-description {
  font-size: 14px;
  font-weight: 400;

  span {
    margin: 0 2px;
    font-weight: 500;
    color: #1677ff;
  }
}

//added by me : Shili
.menu_main {
  margin-top: 10px;
  border: 1px solid #e4e7ec;
  box-shadow:
    0px 12px 16px rgba(16, 24, 40, 0.08),
    0px 6px 4px rgba(16, 24, 40, 0.03);
  border-radius: 8px;
  border: 1px solid #a4ddf8;
  box-shadow: 0px 12px 16px rgba(203, 240, 244, 0.45);
  border-radius: 8px;
  width: 193px;
  margin-top: 5px;


  .drop_down_item {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #177c9a;
    padding: 5px 0px;
    cursor: pointer;

    &:hover {
      background-color: #fafafa !important;
      border-radius: 10px;
    }
  }
}