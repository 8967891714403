.quota-group {
  display: flex;
  justify-content: center;
  column-gap: 60px;
  @media only screen and (max-width: 750px) {
    flex-direction: column-reverse;
    row-gap: 20px;
  }
  &-section1,
  &-section2 {
    width: 50%;
    column-gap: 107px;
    @media only screen and (max-width: 750px) {
      width: 100%;
    }
  }
  &-info {
    margin-bottom: 40px;
    .quota-group-body {
      margin-top: 29px;
    }
    .quota-group-input {
      color: var(--Gray-700, #344054);
      font-size: 12.556px;
      font-style: normal;
      font-weight: 500;
      line-height: 17.938px;
      margin-bottom: 29px;
      label {
        margin-bottom: 6px;
        display: block;
      }
      input {
        border-radius: 7.175px;
        border: 0.897px solid var(--stroke, #e4e7ec);
        background: var(--Base-White, #fff);
        padding: 8.969px 12.556px;
        color: var(--font, #344054);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 21.525px;
        &::placeholder {
          font: inherit;
          color: inherit;
        }
      }
    }
  }
  &-consumption {
    .quota-group-body {
      display: flex;
      justify-content: space-between;
      margin-top: 19px;
      border-radius: 10px;
      border: 1px solid #d0eef1;
      background: #f8fbff;
      padding: 13px;
      .col1,
      .col2 {
        width: 45%;
        .resource {
          width: 100%;
        }
      }
    }
  }
  &-limit {
    border-radius: 10px;
    border: 1px solid var(--stroke, #e4e7ec);
    background: #fbffff;
    padding: 12.617px 14.72px;
    .group-limit {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0;
      &-col1 {
        color: var(--font, #344054);
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 21.028px;
        display: flex;
        align-items: center;
        svg {
          margin-right: 12px;
          width: 25.234px;
          height: 21.028px;
        }
      }
      &-col2 {
        color: var(--font, #344054);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 12.416px;
        border-radius: 4.966px;
        border: 0.621px solid var(--Prim-Blue, #0188f7);
        padding: 6.208px;
      }
    }
  }
  &-users {
    margin-top: 39px;
    &-table {
      border: none;
      padding: 0;
      margin-top: 19px;
    }
  }
  &-users-action {
    display: flex;
    align-items: center;
    column-gap: 15px;
    justify-content: flex-end;
    svg {
      cursor: pointer;
    }
  }
}

.quota-group-details-loading {
  text-align: center;
  margin: 30px auto;
  width: 100%;
}

.quota-group-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.quota-group-title {
  color: var(--Primary-Colors-Primary-800, #177c9a);
  font-size: 16.144px;
  font-style: normal;
  font-weight: 600;
  line-height: 25.113px;
}

.quota-group-button {
  border-radius: 5.44px;
  background: var(--Prim-Blue, #0188f7);
  height: 28.56px;
  padding: 6.8px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-items: center;
  cursor: pointer;
  span {
    color: var(--Primary-Colors-Gray-0, #fafafa);
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 16.32px;
    display: inherit;
    column-gap: 2px;
  }
}
