.dashboard-welcome-card-component {
  margin-bottom: 24px;
  // padding: 22px;
  width: 100%;
  //   display: flex;
  //   justify-content: flex-start;
  //   align-items: flex-start;

  display: grid;
  grid-template-columns: 2fr 1fr;
  width: 100%;
  gap: 24px;

  @media only screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
  }

  .dashboard-welcome-card-component-card {
    padding: 42px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 22px;
    border-radius: 16px;
    width: 100%;
    background: linear-gradient(135deg, rgba(104, 205, 249, 0.2), rgba(7, 141, 238, 0.2))
      rgb(255, 255, 255);

    @media only screen and (max-width: 500px) {
      flex-direction: column;
    }

    .dashboard-welcome-card-component-card-col-1 {
      display: flex;
      flex-direction: column;
      gap: 22px;
    }

    .dashboard-welcome-card-component-card-title {
      font-size: 20px;
      font-weight: 600;
      color: rgb(1, 41, 114);
      text-transform: capitalize;
    }

    .dashboard-welcome-card-component-card-subtitle {
      max-width: 350px;
      font-size: 12px;
      font-weight: 400;
      color: rgb(1, 41, 114);
      text-transform: capitalize;
      margin-bottom: 6px;
    }

    .dashboard-welcome-card-component-card-photo {
      width: 250px;
    }
  }

  .dashboard-welcome-card-component-card-2 {
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    color: rgb(33, 43, 54);
    // transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-image: none;
    overflow: hidden;
    box-shadow:
      rgba(145, 158, 171, 0.2) 0px 0px 2px 0px,
      rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
    border-radius: 16px;
    z-index: 0;
  }
}
