.upgrade-modal {
  .MuiDialog-container {
    .MuiPaper-root {
      padding: 30px !important;
    }
  }
  &-header {
    color: #0188f7;
    text-align: center;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
  &-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 34px;
  }
  &-description {
    color: #344054;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}
