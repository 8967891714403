.no-permission-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 62px;
  justify-content: center;
  align-items: center;

  .no-permission-page-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;

    .no-permission-page-title {
      font-weight: 600;
      font-size: 30px;
      line-height: 45px;
      color: #212b36;
    }

    .no-permission-page-subtitle {
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      color: #637381;
    }
  }

  .no-permission-page-btn {
    padding: 12px 22px;
    border-radius: 8px;
    color: rgb(255, 255, 255);
    background-color: rgb(33, 43, 54);
    font-size: 15px;
  }
}
