.reconnect-modal {
  .ant-modal-footer {
    text-align: center;
  }

  .ant-btn-default {
    width: 210px;
    height: 44px;
    background: #ffffff;
    border: 1px solid #0188f7;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    color: #0188f7;
    transition: all 300ms ease;

    &:hover {
      background-color: #0188f7;
      color: #ffffff;
    }
  }

  .ant-btn-primary {
    width: 210px;
    height: 44px;
    background: #0188f7;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    color: #ffffff;
    transition: all 300ms ease;
    border: 0 !important;

    &:hover {
      background: #0188f7;
      filter: brightness(90%);
    }
  }

  .section_reconnect {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
    flex-direction: column;
    .title {
      color: #0188f7;
      font-feature-settings:
        "clig" off,
        "liga" off;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px; /* 133.333% */
      margin-bottom: 25px;
    }
    .img_section {
      //   width: 80px;
      //   height: 80px;
      flex-direction: column;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ebeff7;
      border-radius: 50%;
      margin-bottom: 25px;
      width: 90px;
      height: 90px;
      color: #0188f7;
    }
    .message {
      margin-bottom: 25px;
      color: var(--color-dark-dark-2, #87898e);
      text-align: center;
      font-feature-settings:
        "clig" off,
        "liga" off;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 137.5% */
    }
  }
}
