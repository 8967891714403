.tag_b {
  border-radius: 8px;
  background: #e5f4fd;
  display: flex;
  padding: 2px 7px;
  align-items: center;
  gap: 7px;
  display: flex;
  align-items: center;
  gap: 7px;
  font-size: 13px;
  line-height: 20px;
}
.active_tag {
  color: var(--font, #344054);
  .active {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background: var(--primary-colors-success-500, #12b76a);
  }
}

.inactive_tag {
  color: var(--font, red);
  .inactive {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background: var(--primary-colors-success-500, red);
  }
}
