.ldap-form {
  padding-bottom: 20px;
  &-header {
    color: #177c9a;
    font-size: 26px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 50px;
  }
  .form-row {
    display: flex;
    align-items: flex-start;
    column-gap: 75px;
    .s_input {
      flex: 1;
    }
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
  .action_form {
    justify-content: flex-start !important;
  }
  form {
    min-width: 500px;
  }
}
