.dashboard-container {
  padding: 32px 24px;

  .dashboard-card-list {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;

    @media only screen and (max-width: 1024px) {
      grid-template-columns: 1fr 1fr;
    }

    @media only screen and (max-width: 720px) {
      grid-template-columns: 1fr;
    }

    .dashboard-card-item {
      border-bottom: 3px solid #eee;
      background-color: #ffffff;
      color: rgb(33, 43, 54);
      transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      overflow: hidden;
      box-shadow:
        rgba(145, 158, 171, 0.2) 0px 0px 2px 0px,
        rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
      border-radius: 16px;
      padding: 24px;

      .dashboard-card-item-content {
        font-weight: 600;
        font-size: 14px;
        text-align: center;
        margin-bottom: 12px;
      }

      .dashboard-card-item-title {
        font-weight: 400;
        font-size: 12px;
        text-align: center;
      }
    }
  }

  .dashboard-charts-cards {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 24px;
    width: 100%;
  }

  .dashboard-card-chart {
    margin-top: 24px;
    position: relative;
    display: flex;
    // width: 50%;

    .dashboard-chart {
      border-bottom: 3px solid #eee;
      background-color: #ffffff;
      color: rgb(33, 43, 54);
      transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      overflow: hidden;
      box-shadow:
        rgba(145, 158, 171, 0.2) 0px 0px 2px 0px,
        rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
      border-radius: 16px;
      padding: 24px;
    }
  }
  .dashboard-user-usage {
    padding: 20px 0;
    &-header {
      display: flex;
      align-items: center;
      column-gap: 12px;
      padding-bottom: 15px;
      border-bottom: 1.075px solid var(--primary-colors-gray-200, #e4e7ec);
      margin-bottom: 15px;
      p {
        color: #000;
        font-size: 15.054px;
        font-weight: 600;
        line-height: 21.506px;
      }
      span {
        color: #026aa2;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        border-radius: 17.204px;
        background: #e0f2fe;
        padding: 1px 4px;
      }
    }
    .ant-table {
      .ant-table-thead {
        .ant-table-cell {
          background: var(--primary-colors-primary-0, #f2fafe);
          color: var(--primary-colors-gray-900, #101828);
          font-family: Poppins;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px;
          div {
            display: flex;
            align-items: center;
            column-gap: 6px;
          }
        }
      }
    }
  }
}

.dashboard-capitalize-title {
  text-transform: capitalize;
}
