.privacy {
  color: #6e6e6e;
  text-align: start;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;

  a {
    color: #6e6e6e;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
  }
}

.disabled-submit-btn {
  background-color: #e0e0e0 !important;
  cursor: not-allowed !important;
  /* Light gray background */
}
