.pagination {
  width: 100% !important;

  .stack_pagination {
    width: 100% !important;
  }
  nav {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100% !important;
    ul {
      border: 1px solid #e4e7ec;
      border-radius: 8px;
      li {
        border-right: 1px solid #e4e7ec;
        border-left: none;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        button {
          width: 100%;
          height: 100%;
          border-radius: 0;
          margin: 0;
        }
        &:last-child {
          border-right: none;
          border-radius: 8px;
        }
      }
    }
  }
}

.active-pagination-btn {
  .Mui-selected {
    background: #a4ddf8 !important;
  }
}
