.stream-quality {
  &-header {
    text-align: center;
    font-size: 17px;
    font-weight: 500;
  }
  &-slider {
    margin: 30px 0 45px 0;
    p {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 15px;
      span {
        font-weight: 600;
      }
    }
  }
}
