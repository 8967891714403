.view-container {
  // height: calc(100vh - 100px) !important;
  display: flex;
  width: 100%;
  height: calc(100vh - 5px);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // background-color: white;
  // background: #000;

  .rdp-view {
    // overflow: hidden;
    // cursor: none;
    height: 100%;
    // width: 100%;
    z-index: 1000;
  }

  .state_session {
    font-weight: 500;
    margin-top: 100px;

    p::first-letter {
      text-transform: uppercase;
    }

    p {
      text-transform: lowercase;
    }
  }
}