.group-table {
  border-radius: 10px;
  border: 1px solid #d0eef1;
  padding: 9px 5px;
  overflow: auto;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  .ant-table {
    max-height: 60vh;
    &-container {
      overflow: auto;
    }
  }
  th.ant-table-cell {
    background: #f2faff !important;
    color: #000;
    font-size: 14.144px;
    font-style: normal;
    font-weight: 500;
    line-height: 25.113px;
    margin-right: 5px;
    white-space: nowrap;
    &::before {
      height: 100%;
      background-color: #d0eef1;
    }
  }
  td.ant-table-cell {
    &:last-child {
      text-align: right;
      .table_actions {
        justify-content: center;
      }
    }
  }
  .ant-table-cell {
    border: none !important;
  }
  .ant-table-tbody {
    .ant-table-row {
      .ant-table-cell {
        border-right: 1px solid #d0eef1 !important;
        white-space: nowrap;
      }
    }
  }
  .group-users {
    display: flex;
    align-items: center;
    .ant-avatar {
      width: 27.542px;
      height: 27.542px;
      border-radius: 27.542px;
      border: 0.765px solid #fff;
      &:not(:first-child) {
        margin-left: -7px;
      }
    }
    &-hidden-users {
      width: 27.542px;
      height: 27.542px;
      border-radius: 50%;
      color: #000;
      font-size: 11.515px;
      background-color: #f5f5f5;
      font-style: normal;
      font-weight: 600;
      line-height: 17.273px;
      -webkit-border-radius: 50%;
      display: flex;
      border: 1px dashed #929292;
      align-items: center;
      justify-content: center;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
    }
  }

  .desktop-group {
    display: flex;
    column-gap: 5px;
    width: 100%;
    max-width: 250px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 0;
      display: none;
    }
    &-item {
      color: var(--Primary-Colors-Gray-700, #344054);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      border-radius: 17.455px;
      border: 1.091px solid var(--Primary-Colors-Gray-700, #344054);
      width: fit-content;
      padding: 0px 7.636px;
      position: relative;
      padding-left: 15px;
      &::before {
        content: "";
        width: 6px;
        height: 6px;
        background-color: #344054;
        position: absolute;
        left: 5px;
        border-radius: 50%;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
      }
    }
  }
  .group-consomption {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 40px;
    div {
      display: flex;
      align-items: center;
      column-gap: 10px;
      span {
        color: #000;
        font-size: 11.515px;
        font-style: normal;
        font-weight: 600;
        line-height: 33.166px;
      }
      svg {
        width: 22px;
        height: 22px;
      }
    }
  }
  .group-status {
    color: #000;
    font-size: 16.144px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.113px;
  }
}
