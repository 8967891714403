.session-name {
  display: flex;
  align-items: center;
  column-gap: 5px;
  span {
    display: block;
    min-width: 40px;
    min-height: 40px;
    max-width: 40px;
    max-height: 40px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #eee;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

// should fixed

.status_column {
  width: fit-content;
  div {
    padding: 2px 7.5px;
    display: flex;
    align-items: center;
    column-gap: 4px;
    justify-content: center;
    border-radius: 16px;
    font-weight: 400;
    font-size: 12px;
    border: 1px solid transparent;
  }
  p {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}
