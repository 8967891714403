.lock_overlay {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.upgrade-icon {
  position: absolute;
  top: 10%;
  left: 50%;
  z-index: 9999;
  transform: translate(-50%, -50%);
}

.upgrade_button {
  // position: absolute;
  // top: 50%;
  // left: 50%;
  z-index: 9999;
  background-color: #1f8af4;
  color: #fff;
  // transform: translate(-50%, -50%);
  cursor: pointer;
  display: flex;
  padding: 9.407px 16.933px;
  justify-content: center;
  align-items: center;
  gap: 7.526px;
  font-size: 13.051px;
  font-weight: 600;
  line-height: 22.577px;
  border-radius: 8px;
  // box-shadow: 0px 0.940710187px 1.8814203739px 0px rgba(16, 24, 40, 0.05);
  height: 40px;

  &:hover {
    color: #fff !important;
  }
}

.upgrade_alert {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 9999;
  transform: translate(-50%, -50%);
  padding: 9.407px 16.933px;
  gap: 7.526px;
  font-size: 13.051px;
  font-weight: 600;
  line-height: 22.577px;
  border-radius: 8px;
  // box-shadow: 0px 0.940710187px 1.8814203739px 0px rgba(16, 24, 40, 0.05);
  width: 90%;
}

.content_alert {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.children_wrapper {
  // background-color: red;
  width: 100%;
  height: 100%;
  padding: 20px;
  border-radius: 15px;
  filter: blur(2px);
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  opacity: 1;
  pointer-events: none;
  user-select: none;
  /* Disable pointer events on the container */
}

.upgrade-popover-button {
  background-color: #1f8af4;
  color: #fff;

  cursor: pointer;
  display: flex;
  width: 200.141px;
  padding: 9.407px 16.933px;
  justify-content: center;
  align-items: center;
  gap: 7.526px;
  font-size: 13.051px;
  font-weight: 600;
  line-height: 22.577px;
  border-radius: 8px;
  box-shadow: 0px 0.940710187px 1.8814203739px 0px rgba(16, 24, 40, 0.05);

  &:hover {
    color: #fff !important;
  }
}

.blured-content {
  // background-color: red;
  width: 100%;
  height: 100%;
  padding: 20px;
  border-radius: 15px;
  filter: blur(2px);
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  opacity: 1;
  pointer-events: none;
  user-select: none;
  /* Disable pointer events on the container */
}
