.form_input {
  text-align: left;
  margin-bottom: 18px;
  label {
    font-weight: 400;
    font-size: 14px;
    color: #101828;
  }
  input {
    margin-top: 6px;
  }
  p {
    color: red;
    font-size: 13px;
    margin-top: 3px;
  }
}

.phone-input-container {
  margin: 6px 0;
  display: flex !important;
  align-items: center;
  border-radius: 8px;
  width: 100%;
  height: 50px;
  overflow: hidden;
  border: 1px solid #a4ddf8;

  .inputphone {
    border: none !important;
    height: 100%;
    width: 100%;
    margin: 0;
    outline: none;
    padding: 6px;
  }

  .ant-select-selector {
    border: none !important;
    overflow: hidden !important;
    border-radius: 0 !important;
  }

  .ant-select-focused {
    border: none !important;
    overflow: hidden !important;
    border-radius: 0 !important;
  }
}

.select-flag-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;

  .country-flag {
    width: 18px;
    height: 18px;
  }
}
