.form_input {
  text-align: left;
  margin-bottom: 18px;
  label {
    font-weight: 400;
    font-size: 14px;
    color: #101828;
  }
  input {
    margin-top: 6px;
  }
  input:disabled {
    cursor: not-allowed;
  }
  p {
    color: red;
    font-size: 13px;
    margin-top: 3px;
  }

  .input-eye-icon-container {
    position: relative !important;
  }

  .eye-icon {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
  }
}
