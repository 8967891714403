.transferfile {
  & > div > div {
    max-width: 80% !important;
    min-width: 80% !important;
    height: 80%;
  }

  &-content {
    display: flex;
    align-items: center;
  }
  &-files {
    height: 100%;
    min-width: 50%;
    max-width: 50%;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    overflow: auto;
    padding-right: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  &-details {
    display: flex;
    justify-content: space-between;
    p {
      line-height: 30px;
      font-size: 15px;
      span:first-child {
        font-weight: 700;
        margin-right: 5px;
      }
    }
    &-info {
      max-width: 65%;
      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &--title {
      font-size: 25px !important;
      font-weight: 500;
      margin-bottom: 8px;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &--items {
      margin-bottom: 10px;
    }
    &--buttons {
      display: flex;
      flex-direction: column;
      row-gap: 15px;
    }
  }
  &-section {
    min-width: 50%;
    max-width: 50%;
    height: 100%;
    padding-left: 15px;
    overflow: auto;
  }

  &-dragger {
    flex: 1;
    & > div.ant-upload {
      height: 100% !important;
      background: #e3f3ff;
      border: 1.23775px dashed #a7a7a7;
      border-radius: 12.3775px;
    }
    .ant-upload {
      &-text {
        font-weight: 500;
        font-size: 23.5172px;
        color: #374957;
      }
      &-hint {
        font-weight: 400;
        font-size: 16.0907px;
        color: #686868;
      }
    }
  }
  &-upload {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .upload-item {
    display: flex;
    align-items: center;
    column-gap: 15px;
    width: 100%;
    padding: 11px 12px;
    background: #f3faff;
    border-radius: 5px;
    margin-top: 15px;
    position: relative;
    &:hover {
      .close-icon {
        opacity: 1;
      }
    }
    .close-icon {
      transition: all 0.3s;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -o-transition: all 0.3s;
      position: absolute;
      opacity: 0;
      top: 2px;
      right: 10px;
      cursor: pointer;
      svg {
        width: 16px;
      }
    }
    &-icon {
      img,
      svg {
        width: 40px;
        height: 40px;
      }
    }
    &-info {
      width: 100%;
      & > p {
        font-weight: 400;
        font-size: 15px;
        line-height: 15px;
        color: #6a6a6a;
      }
      .ant-progress-bg {
        background: #21d2cd;
        box-shadow: 0px 1px 3px rgba(33, 210, 205, 0.65);
      }
      .ant-progress-inner {
        overflow: visible;
      }
      .ant-progress-text {
        font-weight: 400;
        font-size: 12px;
        color: #558094;
      }
    }
  }
  .ant-tree {
    max-height: 76%;
    overflow: auto;
  }
}
