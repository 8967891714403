.templates_page-relative {
  position: relative;
}

.templates_page {
  display: flex;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  // flex-direction: column;
  width: 100%;
  height: calc(100vh - 260px);

  @media only screen and (max-height: 500px) {
    height: calc(100vh - 110px);
  }

  overflow: auto;

  &-content {
    flex: 1;

    .desktops {
      display: flex;
      gap: 20px;
      padding: 48px 95px 20px 95px;
      flex-wrap: wrap;
      width: 100%;

      @media only screen and (max-width: 1800px) {
        padding: 35px 65px 15px;
        gap: 20px;
      }

      @media only screen and (max-width: 1300px) {
        padding: 20px 30px;
        justify-content: flex-start;
        justify-content: center !important;
      }
    }
  }

  .empty_data {
    text-align: center;
    width: 100%;
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 19px;
    max-width: 500px;
    margin: 0 auto;
    line-height: 31px;
    text-transform: capitalize;

    &-owner {
      div {
        cursor: pointer;
        display: flex;
        width: 140px;
        padding: 5px 12px;
        justify-content: center;
        align-items: center;
        gap: 7.526px;
        color: var(--prim-blue, #0188f7);
        font-size: 14px;
        font-weight: 500;
        margin: 0 auto;
        margin-top: 20px;
        line-height: 22.577px;
        background-color: transparent;
        border-radius: 8px;
        border: 0.941px solid var(--prim-blue, #0188f7);
        background: var(--base-white, #fff);
        box-shadow: 0px 0.940710187px 1.8814203739px 0px rgba(16, 24, 40);
        background-color: #1f8af4;
        color: #fff;
      }
    }
  }

  .loading_screen {
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70%;
  }
}

.groups-list {
  background: #a5cff2;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;

  div {
    padding: 6px 12px;
    cursor: pointer;

    &:hover {
      background-color: #e9f5ff;
    }

    transition: all 0.2s;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
  }
}

// loader
.loader {
  border-radius: 50%;
  border: 4px solid rgba(0, 136, 247, 0.4);
  border-left-color: transparent;
  width: 36px;
  height: 36px;
  animation: spin89345 1s linear infinite;
}

@keyframes spin89345 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

#site {
  opacity: 0;
  transition: all 2s ease;
}

#preloader {
  height: 40px;
  width: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -(40px / 2);
  margin-left: -(40px / 2);

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: -(2px/ 2);
    top: -(2px/ 2);
    height: 100%;
    width: 100%;
    animation: rotation 1s linear infinite;
    border: (2px/ 2) solid #0088f7;
    border-top: (2px/ 2) solid transparent;
    border-radius: 100%;
  }

  &>.icon {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 25px;
    width: 10.6px;
    margin-top: -(25px / 2);
    margin-left: -(10.6px / 2);
    animation: wink 1s ease-in-out infinite alternate;
  }
}

@media only screen and (min-width: 768px) {
  #preloader {
    height: (40px * 1.5);
    width: (40px * 1.5);
    margin-top: -((40px / 2) * 1.5);
    margin-left: -((40px / 2) * 1.5);

    &:before {
      left: -2px;
      top: -2px;
      border-top-width: 2px;
      border-left-width: 2px;
      border-bottom-width: 2px;
      border-right-width: 2px;
    }

    &>.icon {
      height: (25px * 1.5);
      width: (10.6px * 1.5);
      margin-top: -((25px / 2) * 1.5);
      margin-left: -((10.6px / 2) * 1.5);
    }
  }
}

@media only screen and (min-width: 1200px) {
  #preloader {
    height: (40px * 2);
    width: (40px * 2);
    margin-top: -((40px / 2) * 2);
    margin-left: -((40px / 2) * 2);

    &>.icon {
      height: (25px * 2);
      width: (10.6px * 2);
      margin-top: -((25px / 2) * 2);
      margin-left: -((10.6px / 2) * 2);
    }
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

@keyframes wink {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.macos-desktop {
  cursor: pointer;

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #000000;

    @media only screen and (max-width: 1800px) {
      font-size: 13px;
    }

    @media only screen and (max-width: 1300px) {
      font-size: 11px;
      max-width: 80px;
    }
  }

  img {
    width: 62px;
    height: 62px;
    border-radius: 5px;
    margin-bottom: 2px;

    // @media only screen and (max-width: 1800px) {
    //   width: 53px;
    //   height: 53px;
    // }

    // @media only screen and (max-width: 1300px) {
    //   width: 33px;
    //   height: 33px;
    // }
  }


}