.view_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  width: 100%;
  border-bottom: 1px solid #e8e8e8;
  padding: 8px 15px;
  // height: 62px;
  position: relative;
  width: 100%;
  gap: 30px;

  @media only screen and (max-width: 500px) {
    flex-direction: column;
    gap: 12px;
  }

  &>.view_title__left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    white-space: nowrap;

    .tag {
      background: #e0f2fe;
      border-radius: 16px;
      padding: 2px 7px;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #026aa2;
      white-space: nowrap;
    }
  }

  .view_title__right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    width: 100%;

    .add_btn {
      background: #0188f7;
      border-radius: 8px;
      // border: 1px solid #e4e7ec;
      border-radius: 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #ffffff;
      padding: 9px 26px;
      cursor: pointer;
      white-space: nowrap;
      border: none;

      // &:hover {
      //   background-color: #f8fafe;
      // }
    }

    .delete_btn {
      border-color: red;
      color: red;
    }
  }

  .search_data {
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 396px;

    // border: 1px solid #e4e7ec;
    // border-radius: 80px;
    span {
      display: flex;
      justify-content: center;
      align-items: center;

      .ant-input-prefix {
        margin-right: 12px;
      }

      input {
        height: 32px;

        &::placeholder {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 24px;
          color: #98a2b3;
        }
      }

      .ant-input-group-addon {
        button {
          height: 45px !important;
        }
      }
    }

    .ant-input-affix-wrapper {
      border-radius: 8px;
      border: 1px solid #e4e7ec;
    }
  }

  .dropDown {
    height: 100%;
    background: #ffffff;
    border: 1px solid #e4e7ec;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #344054;
    padding: 9px 0px;
    cursor: pointer;
    white-space: nowrap;
    user-select: none;
    width: 209px;
    max-height: 42px;
    display: flex;
    justify-content: center;

    @media only screen and (max-width: 1060px) {
      width: 100%;
    }

    .drop_down_content {
      padding: 9px 12px !important;
      width: 100%;

      @media only screen and (max-width: 1060px) {
        width: none;
      }
    }

    .ant-dropdown-trigger {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .ant-space {
        width: 100%;
      }

      .ant-space.ant-space-horizontal.ant-space-align-center {
        width: 100%;
        justify-content: space-between;
        align-items: center;

        @media only screen and (max-width: 1060px) {
          width: 56%;
        }
      }
    }

    .ant-space-item {
      display: flex;
      align-items: center;
      justify-content: center;

      .left_drop {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        color: #98a2b3;

        img {
          width: 16px;
        }
      }
    }
  }
}

.view_title_drop {
  .ant-dropdown-menu.ant-dropdown-menu-vertical.ant-dropdown-menu-light {
    margin-top: 0px;
    border-radius: 0;
    background: #ffff;
    border: 1px solid #e4e7ec;
    border-radius: 0 0 8px 8px;
    border-top: none;
    box-shadow: none;
    width: 209px;
    left: -0.5px;

    @media only screen and (max-width: 1060px) {
      width: 100%;
      left: 0px;
      border-radius: 8px;
      margin-top: 7px;
    }

    .ant-dropdown-menu-item {
      border-radius: 8px;

      height: 33px;

      &:hover {
        background: #e5f4fd;
        // color: #177c9a;
      }

      .i_drop_down {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #374957;

        &:hover {
          color: #177c9a;
        }
      }
    }
  }
}

// .modal_delete_multiple {
.ant-modal-content {
  width: 500px;

  @media only screen and (max-width: 1060px) {
    width: 100%;
  }

  .ant-modal-body {
    .ant-modal-confirm-body-wrapper {

      //before
      .ant-modal-confirm-body {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .ant-modal-confirm-title {
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          color: #101828;
          margin-top: 16px;
          margin-bottom: 5px;
        }

        //content
        .ant-modal-confirm-content {
          margin: 0;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #475467;
          margin-bottom: 10px;
        }

        //btns
      }

      .ant-modal-confirm-btns {
        display: flex;

        button {
          width: 100%;
          box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
          border-radius: 8px;
          background: #ffffff;
          height: 44px;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #344054;
        }

        &> :last-child {
          background: #d92d20;
          color: #ffff;
          box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        }
      }
    }
  }
}

// }

.add-btn {
  background-color: #0188f7 !important;
  color: #fafafa !important;
}