.desktop_card {
  width: 94px;
  position: relative;
  text-align: center;
  cursor: pointer;
  transition-duration: 0.6s;
  border: 1px solid #fff;
  border-radius: 12px;
  margin-bottom: 0;

  .not-available {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 27px;
    background: linear-gradient(
      180deg,
      rgba(108, 215, 249, 0.26) 0%,
      rgba(45, 144, 239, 0.22) 100%
    );
    box-shadow: 0px 4px 9.2px 0px rgba(209, 232, 252, 0.48);
    backdrop-filter: blur(2px);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;

    .upgrade-btn {
      padding: 3px 7px;
      border-radius: 8px;
      background: #dcedff;
      border: 1px solid #0188f7;
      box-shadow: 0px 0px 6px #2fafffd2;
      transition: all 0.5s ease 0s;
      font-size: 12px;
      font-weight: 500;
      color: #667085;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
    }

    transition: all 0.2s;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
  }

  .block-btn,
  .upgrade-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: transform 0.3s ease;
  }

  .block-btn {
    z-index: 2;
    opacity: 1;
    width: 45px;
    height: 45px;
  }

  .upgrade-btn {
    transform: translate(-50%, -50%) translateX(94px);
    z-index: 1;
    -webkit-transform: translate(-50%, -50%) translateX(94px);
    -moz-transform: translate(-50%, -50%) translateX(94px);
    -ms-transform: translate(-50%, -50%) translateX(94px);
    -o-transform: translate(-50%, -50%) translateX(94px);
  }

  .cancel-animation .block-btn {
    transform: translate(-50%, -50%) translateX(0) !important;
    transition: none;
    -webkit-transform: translate(-50%, -50%) translateX(0) !important;
    -moz-transform: translate(-50%, -50%) translateX(0) !important;
    -ms-transform: translate(-50%, -50%) translateX(0) !important;
    -o-transform: translate(-50%, -50%) translateX(0) !important;
  }

  .cancel-animation .upgrade-btn {
    display: none;
  }

  position: relative;
  overflow: hidden;
  &-content {
    border-radius: 8px;
    padding: 5px 2px;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100%;
      background: linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.2), transparent);
      transition: all 650ms;
    }

    &:hover:before {
      left: 100%;
    }
  }

  @media only screen and (max-width: 1800px) {
    width: 84px;
  }
  @media only screen and (max-width: 1300px) {
    width: 74px;
  }
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #000000;
    @media only screen and (max-width: 1800px) {
      font-size: 13px;
    }
    @media only screen and (max-width: 1300px) {
      font-size: 11px;
      max-width: 80px;
    }
  }
  img {
    width: 62px;
    height: 62px;
    border-radius: 5px;
    margin-bottom: 2px;
    @media only screen and (max-width: 1800px) {
      width: 53px;
      height: 53px;
    }
    @media only screen and (max-width: 1300px) {
      width: 33px;
      height: 33px;
    }
  }
}
