.assign_modal {
  & > div {
    & > div {
      width: 570px !important;
    }
  }
  .assign_btn {
    /* Shadow / xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
  .list_users {
    margin-bottom: 18px;
    margin-top: 18px;
  }
  .img_assign {
    width: 40px;
  }
  .action_confirm {
    display: flex;
    gap: 20px;
    button {
      border: none;
      display: flex;
      padding: 10px 18px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      flex: 1 0 0;
      border-radius: 8px;
      border: 1px solid var(--prim-blue, #0188f7);
      background: var(--prim-blue, #0188f7);
      color: var(--base-white, #fff);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
      cursor: pointer;
    }
    .cancel {
      border: 1px solid var(--prim-blue, #0188f7);
      background: var(--base-white, #fff);
      color: var(--prim-blue, #0188f7);
    }
  }
}
