.users {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  .images {
    display: flex;
    align-items: center;
    img {
      width: 36px;
      height: 36px;
      object-fit: fill;
      border-radius: 50%;
      margin-left: -8px;
      border: 1px solid #fff;

      &:first-child {
        margin-left: 0 !important;
      }
    }
    .btn_us {
      width: 37px;
      height: 37px;
      border: none;
      border-radius: 50%;
      margin-left: -8px;
      cursor: pointer;
      border-radius: 36px;
      border: 1px dashed #d1d1d1;
      background: #f5f5f5;
    }
  }
  p {
    color: #000;
    font-size: 14.051px;
    font-weight: 500;
    line-height: 22.577px;
  }
}
