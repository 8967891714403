.ant-select-show-search {
  z-index: 10;
  min-height: 44px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  border: none;
  margin-bottom: 20px;
  & > div {
    min-height: 44px;
    background: #ffffff;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
  }
}

.error_txt {
  color: red;
  font-size: 11px;
  margin-bottom: 12px;
}
