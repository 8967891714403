.manage-quote {
  &-title {
    color: var(--Primary-Colors-Primary-800, #177c9a);
    font-size: 16.144px;
    font-style: normal;
    font-weight: 600;
    line-height: 25.113px;
    margin-bottom: 12px;
  }
  &-container {
    border-radius: 8px;
    border: 1px solid #d0eef1;
    padding: 18px 28px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    .quote-tabs {
      margin-bottom: 21px;
      .ant-segmented {
        padding: 5px 11px;
        border-radius: 8px;
        background: #e5f4fd;
        .ant-segmented-thumb {
          border-radius: 8px;
          -webkit-border-radius: 8px;
          -moz-border-radius: 8px;
          -ms-border-radius: 8px;
          -o-border-radius: 8px;
        }
        .ant-segmented-group {
          flex-wrap: wrap;
          justify-content: flex-start;
          gap: 12px;
          .ant-segmented-item {
            .ant-segmented-item-label {
              padding: 8px 17px;
              border-radius: 8px;
              color: #57a6bd;
              font-size: 15px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px;
              background-color: transparent;
            }
            &-selected {
              color: var(--Primary-Colors-Primary-800, #177c9a);
              box-shadow: 0px 0px 6px 0px #cce7f8;
              background-color: #fff;
              border-radius: 8px;
            }
          }
        }
      }
      .tab-label {
        display: flex;
        align-items: center;
        column-gap: 10px;
      }
    }
  }
}
