.loading-component {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // z-index: 999;

  .loader {
    border-radius: 50%;
    border: 4px solid rgba(0, 136, 247, 0.4);
    border-left-color: transparent;
    width: 36px;
    height: 36px;
    animation: spin89345 1s linear infinite;
  }

  @keyframes spin89345 {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  #site {
    opacity: 0;
    transition: all 2s ease;
  }

  #preloader {
    height: 40px;
    width: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -(40px / 2);
    margin-left: -(40px / 2);
    &:before {
      content: "";
      display: block;
      position: absolute;
      left: -(2px/ 2);
      top: -(2px/ 2);
      height: 100%;
      width: 100%;
      animation: rotation 1s linear infinite;
      border: (2px/ 2) solid #0088f7;
      border-top: (2px/ 2) solid transparent;
      border-radius: 100%;
    }
    & > .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 25px;
      width: 10.6px;
      margin-top: -(25px / 2);
      margin-left: -(10.6px / 2);
      animation: wink 1s ease-in-out infinite alternate;
    }
  }

  @media only screen and (min-width: 768px) {
    #preloader {
      height: (40px * 1.5);
      width: (40px * 1.5);
      margin-top: -((40px / 2) * 1.5);
      margin-left: -((40px / 2) * 1.5);
      &:before {
        left: -2px;
        top: -2px;
        border-top-width: 2px;
        border-left-width: 2px;
        border-bottom-width: 2px;
        border-right-width: 2px;
      }
      & > .icon {
        height: (25px * 1.5);
        width: (10.6px * 1.5);
        margin-top: -((25px / 2) * 1.5);
        margin-left: -((10.6px / 2) * 1.5);
      }
    }
  }

  @media only screen and (min-width: 1200px) {
    #preloader {
      height: (40px * 2);
      width: (40px * 2);
      margin-top: -((40px / 2) * 2);
      margin-left: -((40px / 2) * 2);
      & > .icon {
        height: (25px * 2);
        width: (10.6px * 2);
        margin-top: -((25px / 2) * 2);
        margin-left: -((10.6px / 2) * 2);
      }
    }
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  @keyframes wink {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
