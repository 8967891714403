.action_type {
  margin-bottom: 25px;
  .btn_action {
    display: flex;
    gap: 25px;
    .btn {
      display: flex;
      padding: 10px 14px;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      border-radius: 8px;
      border: 1px solid var(--primary-colors-primary-600, #21afda);
      background: #e5f4fd;
      width: 115px;
      height: 40px;
      color: var(--primary-colors-gray-600, #475467);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }
    .btn_active {
      background: var(--primary-colors-primary-500, #25c4f4);
      color: var(--primary-colors-gray-0, #fafafa);
    }
  }
}
