.profile {
  &_content {
    margin-top: 35px;
    & > div {
      border: 1px solid #e4e7ec;
      border-radius: 8px;
      padding: 15px 20px;
      margin-bottom: 30px;
    }
  }
  &_info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .user-info {
      display: flex;
      align-items: center;
      column-gap: 35px;

      &-photo {
        width: 103px;
        height: 103px;
        border-radius: 50%;
        overflow: hidden;

        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        & > div,
        img {
          width: 100%;
          height: 100%;
        }
      }
      p {
        font-weight: 400;
        font-size: 15px;
        color: #667085;
        line-height: 28px;
      }
      p:nth-child(1) {
        font-weight: 500;
        color: #000000;
      }
    }
  }

  .profile_personal_info {
    .personal-info-header {
      font-weight: 500;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 35px;
    }
    .personal-info-content {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      justify-content: space-between;
      & > div {
        width: calc(50% - 15px);
        min-width: 200px;
        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 30px;
        }
        p:first-child {
          color: #667085;
        }
      }

      .personal-info-input {
        padding: 8px 12px;
      }
    }
  }

  .edit-button {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 8px;
    font-weight: 400;
    font-size: 15px;
    color: #344054;
    padding: 10px;
    border: 1px solid #e4e7ec;
    border-radius: 8px;
    cursor: pointer;
  }

  .edit-save-btn {
    margin-top: 22px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  #update-my-pic {
    display: none;
  }
}

.profile_pages {
  width: 100% !important;

  .ProfileV2-page {
    margin: 42px 22px;
    display: flex;
    justify-content: center;
    align-items: center;

    .ProfileV2-page-row {
      width: 80%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 32px;

      .ProfileV2-page-card {
        padding: 42px 32px;
        border-radius: 16px;
        background-color: rgb(255, 255, 255);
        color: rgb(33, 43, 54);
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        box-shadow:
          rgba(145, 158, 171, 0.2) 0px 0px 2px 0px,
          rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 22px;
      }

      .ProfileV2-page-col-1 {
        width: 30%;
      }

      .ProfileV2-page-col-2 {
        width: 70%;
        margin-bottom: 32px;

        .ant-divider {
          color: rgb(99, 115, 129);
          font-size: 14px;
          margin: 0;
        }
      }

      .ProfileV2-page-photo-border {
        padding: 8px;
        border-radius: 50%;
        border: 1px dashed rgba(145, 158, 171, 0.2);
      }

      .ProfileV2-page-photo {
        cursor: pointer;
        border-radius: 50%;
        overflow: hidden;
        position: relative;
        display: flex;

        .ProfileV2-page-photo-hover {
          display: none;
          cursor: pointer;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.45);
          border-radius: 50%;

          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 6px;

          .ProfileV2-page-photo-hover-title {
            line-height: 1.5;
            font-size: 0.75rem;
            font-weight: 400;
            color: #fff;
            text-align: center;
            font-weight: 600;
          }
        }

        &:hover {
          .ProfileV2-page-photo-hover {
            display: flex;
          }
        }
      }

      .ProfileV2-page-photo-description {
        width: 70%;
        line-height: 1.5;
        font-size: 0.75rem;
        font-weight: 400;
        color: rgb(145, 158, 171);
        text-align: center;
      }

      .ProfileV2-page-photo-title {
        line-height: 1.57143;
        font-size: 0.875rem;
        font-weight: 400;
      }

      .ProfileV2-page-col-2-label {
        line-height: 1.5;
        font-size: 12px;
        font-weight: 600;
        color: rgb(99, 115, 129);
      }

      .ProfileV2-page-col-2-form-input {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 22px;
        width: 100%;
      }

      .ProfileV2-page-col-2-form-input-width {
        width: 100%;
      }

      .personal-info-input {
        padding: 12px !important;
      }

      .personal-info-input-disabled {
        color: #94a3b8;
        background-color: #f8fafc;
      }

      .personal-info-input-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        justify-self: center;
        gap: 6px;
      }

      .personal-info-input-domain {
        height: 58px;
      }

      .ProfileV2-page-col-2-form-btn {
        width: 100%;
        text-align: end;
        margin-bottom: 12px;

        .ProfileV2-page-col-2-form-btn-confirm {
          cursor: pointer;
          width: 180px;
          height: 44px;
          background: #0188f7;
          box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
          border-radius: 8px;
          color: #fff;
          transition: all 300ms ease;
          border: 1px solid #0188f7;

          &:hover {
            background-color: #0b7ad5;
            color: #ffffff;
          }
        }
      }

      @media only screen and (max-width: 920px) {
        flex-direction: column;

        .ProfileV2-page-col-1,
        .ProfileV2-page-col-2 {
          width: 100%;
        }
      }

      @media only screen and (max-width: 500px) {
        width: 100%;
      }
    }
  }
}
.ProfileV2-page-col-2-form-general {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 22px;
}

.profile_history {
  &-header {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 35px;
  }
  .history-container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    overflow: auto;
    &-header {
      display: flex;
      column-gap: 10px;
      align-items: center;
    }
    &-body {
      display: flex;
      flex-direction: column;
      .history-row {
        display: flex;
        gap: 10px;
      }
    }
  }

  .grid-item {
    background-color: #fff;
    padding: 12px 20px;
    text-align: center;
    height: 44px;
    align-items: center;
    border: 0px solid #e4e7ec;
    border-width: 0px 1px 1px 0px;
    box-shadow: -1px 0px 9px -10px rgba(0, 0, 0, 0.47);
    color: var(--font, #344054);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    white-space: nowrap;
    min-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc((100% - 40px) / 5);
  }
  .grid-header {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    width: calc((100% - 40px) / 5);
  }
}

.table-data-container {
  overflow-x: auto;
}

.user-history-text-no-wrap {
  text-wrap: nowrap;
}

.center-antd-spinner {
  display: flex;
  justify-content: center;
  margin: 12px;
}

.ProfileV2-page-col-2-form-general-verify {
  width: 100%;
  background-color: #e6f4ff;
  border: 1px solid #91caff;
  padding: 8px 12px;
  border-radius: 8px;
}

// ###
// ###
// ###
// *** renew ***
// ###
// ###
// ###

.profile-page-container {
  padding: 62px 12px;
  display: flex;
  justify-content: center;
  align-items: center;

  .profile-page-content {
    padding: 32px;
    border-radius: 10px;
    background: #f0fafb;
    width: 95%;
    min-height: 77vh;
    height: 100%;
    display: grid;

    @media only screen and (max-width: 720px) {
      width: 100%;
      padding: 12px;
    }

    .profile-page-content-row {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 32px;

      @media only screen and (max-width: 1250px) {
        flex-direction: column;
        align-items: center;
      }

      .profile-page-content-col-1 {
        min-width: 365px;
        height: 100%;
        border-radius: 20px;
        background: #fff;
        padding: 32px;
        display: flex;
        flex-direction: column;
      }

      .profile-page-content-col-2-content {
        width: 100%;
        display: grid;
        gap: 22px;
      }

      .profile-page-content-col-2 {
        width: 100%;
        padding: 32px;
        border-radius: 20px;
        background: #fff;
        display: grid;
        gap: 38px;

        .profile-page-content-col-2-tabs {
          border-radius: 8px;
          background: #e5f4fd;
          height: 58px;
          padding: 6px 11px;
          width: fit-content;

          @media only screen and (max-width: 720px) {
            height: 80px;
          }

          @media only screen and (max-width: 470px) {
            height: 120px;
          }

          .ant-segmented {
            background-color: transparent;
            height: 43px;

            .ant-segmented-group {
              height: 43px;
              gap: 12px;

              @media only screen and (max-width: 720px) {
                flex-wrap: wrap;
              }

              .ant-segmented-item {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 8px;
                padding: 0 12px;
              }

              .ant-segmented-item-selected {
                background: #fff;
                box-shadow: 0px 0px 6px 0px #cce7f8;
              }
            }
          }
        }
      }
    }
  }
}

.profile-page-container-menu-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  color: #177c9a;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.profile-page-delete-my-profile {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;

  flex: auto;
  justify-content: flex-end;

  button {
    padding: 6px 18px;
    border-radius: 9px;
    border: 0;
    color: white;
    background-color: #fb4c50;
    cursor: pointer;

    &:hover {
      background-color: #f97066;
    }
  }

  .profile-page-delete-my-profile-delete-profile {
    background-color: transparent;
    border: 1px solid #fb4c50;
    color: #fb4c50;

    cursor: pointer;

    &:hover {
      background-color: #fb4c50;
      color: white;
    }
  }

  .profile-page-delete-my-profile-request-data {
    background-color: transparent;
    border: 1px solid #0188f7;
    color: #0188f7;

    cursor: pointer;

    &:hover {
      background-color: #0188f7;
      color: white;
    }
  }
}

.delete-my-profile-org-container {
  padding: 22px 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 22px;

  .delete-my-profile-org-container-title {
    // width: 700px;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #f04438;
  }

  .delete-my-profile-org-container-subtitle {
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: #475467;
    width: 100%;

    & > span {
      font-weight: 600;
      font-size: 14px;
      text-align: center;
      color: #f04438;
    }
  }

  .delete-my-profile-org-container-confirm {
    margin-top: 12px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;

    & > p {
      text-align: left;

      .delete-my-profile-org-container-subtitle-bold {
        font-weight: 600;
        font-size: 14px;
        text-align: center;
        color: #475467;
      }
    }
  }

  .delete-my-profile-org-container-btns {
    margin-top: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 18px;
    width: 100%;

    @media only screen and (max-width: 500px) {
      flex-direction: column-reverse;

      & > button {
        width: 100%;
      }
    }

    & > button {
      padding: 12px 62px;
      border: none;
      border-radius: 8px;
      cursor: pointer;
      box-shadow: 0 1px 2px #1018280d;
      font-size: 14px;
    }

    .delete-my-profile-org-container-btns-cancel {
      border: 1px solid #0188f7;
      background-color: white;
      color: #0188f7;
    }

    .delete-my-profile-org-container-btns-confirm {
      border: 1px solid #0188f7;
      background-color: #0188f7;
      color: white;
    }
  }
}

.ant-modal-content {
  width: fit-content !important;
}
