.settings {
  padding: 55px 60px;

  &_title {
    font-weight: 500;
    font-size: 18px;
    color: #0188f7;
  }
}

.profile-settings {
  padding: 0;

  .security-container {
    margin-top: 0;
  }
}

.profile_layout {
  display: flex;
  .profile_layout__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 100vh;
    overflow-y: scroll;
    .flex_nav {
      display: flex !important;
    }
    .header {
      position: sticky;
      top: 0;
      z-index: 8;
      // position: absolute;
      top: 0;
      transition: all 0.5s;
    }
    main {
      transition: all 0.5s;
      height: 100vh;
    }
  }
  .profile_pages {
    width: calc(100% - 270px);
    @media only screen and (max-width: 1089px) {
      width: calc(100% - 70px);
    }
  }
}
