.view_tabs {
  padding: 10px 10px;
  .ant-segmented {
    padding: 7px 16px;
    // margin: 0 30px;
    background-color: #e5f4fd;
    .ant-segmented-group {
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 12px;
      .ant-segmented-item {
        padding: 6px 23px;
        border-radius: 8px;

        .ant-segmented-item-label {
          color: #667085;
          font-weight: 400;
          font-size: 14px;
          &::after {
            color: red !important;
          }
        }
      }
      .ant-segmented-item-selected {
        .ant-segmented-item-label {
          color: #177c9a !important;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
        }
      }
    }
  }
}

.view_tabs {
  .ant-tabs-nav {
    margin: 0 !important;
    // height: 60px;
    padding: 5px 16px 0px;

    .ant-tabs-nav-wrap {
      padding: 0 10px;
      .ant-tabs-nav-list {
        .ant-tabs-tab-btn {
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          color: #667085;
          font-family: "Poppins";
          font-style: normal;
          box-shadow: inset;
          margin-bottom: -2px;
          &:hover {
            font-weight: 500;
          }
        }
        .ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            font-weight: 500;
          }
          //   color: red !important;
        }
      }
      .ant-tabs-ink-bar {
        background-color: #667085;
        height: 1px;
        // min-width: 50% !important;
        // margin-left: -6px !important;
      }
    }
  }
}
