.desktops_header_page {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  background-color: #f8ffff;
  width: calc(100% - (200px + 114px));
  @media only screen and (max-width: 1300px) {
    padding: 10px 0 10px 30px;
  }

  .active_sessions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 26px;
      height: 26px;
      background: #e4e7ec;
      border-radius: 50%;
      font-weight: 400;
      font-size: 14px;
      color: #344054;
    }
    p {
      font-weight: 500;
      font-size: 14px;
      color: #000000;
      white-space: nowrap;
    }
  }

  .active_desktops {
    width: calc(100% - (84px + 150px + 60px));
    overflow: hidden;
    display: flex !important;
    flex-direction: row;
    padding: 0 0 0 3px;
    flex: 1;
  }
  ::-webkit-scrollbar {
    display: none;
  }

  .no_data {
    width: 100% !important;
    text-align: center;
    font-size: 15px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &--buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
    button {
      width: 38px;
      height: 38px;
      position: static;
      & > div {
        background: #ffffff;
        box-shadow: 0px 4px 7px rgba(204, 231, 248, 0.33);
        border-radius: 100px;
        &:hover {
          background-color: #d0d5dd !important;
        }
      }
    }
    button:first-child {
      svg {
        transform: rotate(180deg);
      }
    }
    .disable {
      pointer-events: none; //better than cursor: default;
      opacity: 0.65;
    }
  }
  &--home {
    width: 43px;
    height: 43px;
    background: #ffffff;
    box-shadow: 0px 4px 14px rgba(2, 187, 240, 0.14);
    border-radius: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 8px;
  }
}

#smallView {
  .active_desktop {
    width: 90px;
    .desktop_left-info {
      display: none;
    }
  }
}

@media only screen and (max-width: 425px) {
  .desktops_header_page {
    .active_sessions {
      display: none;
    }
  }
}

.my-custom-swiper {
  width: 100% !important;
  display: flex !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  overflow: hidden !important;

  .my-custom-swiper-item {
    width: 206px !important;
  }
}

.swiper {
  width: 100%;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.desktops_header_page_my_arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;

  .desktops_header_page_my_arrow_btn {
    width: 43px;
    height: 43px;
    background: #ffffff;
    box-shadow: 0px 4px 14px rgba(2, 187, 240, 0.14);
    border-radius: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 8px;
    user-select: none;
  }

  .desktops_header_page_my_arrow_btn_reverse {
    transform: scaleX(-1);
  }
}
