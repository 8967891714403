.config-desktop-modal {
  .config-desktop-modal-content {
    padding: 28px 55px;

    .config-desktop-modal-title {
      padding: 0 10px;
      padding-bottom: 12px;
      border-bottom: 1.11767px solid #e4e7ec;
      margin-bottom: 26px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 45px;

      .logo-icon {
        width: 60px;
        height: 60px;
      }

      .close-icon {
        cursor: pointer;
        position: absolute;
        top: 20px;
        right: 20px;
      }

      .text-container {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .title {
          font-weight: 600;
          font-size: 16.9328px;
          line-height: 26px;
          color: #101828;
        }

        .subtitle {
          font-weight: 400;
          font-size: 13.1699px;
          line-height: 19px;
          color: #475467;
        }
      }
    }

    .config-rows {
      display: flex;
      justify-content: space-around;
      align-items: center;
      gap: 12px;
      margin-bottom: 20px;
    }
  }
}

.config-item {
  padding: 12px 20px;
  border-radius: 12px;
  border: 1px solid #e4e7ec;
  background-color: white;
  width: 175.3px;
  // height: 225.48px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  cursor: pointer;

  &:hover {
    background: #dcedff;
    border: 1px solid #0188f7;
    box-shadow: 0px 0px 6.30845px #cce7f8;
  }

  .item-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    width: 100%;

    .item-title {
      font-weight: 400;
      font-size: 10.3478px;
      line-height: 19px;
      color: #344054;
    }

    .item-value {
      font-weight: 500;
      font-size: 7.77587px;
      line-height: 11px;
      color: #344054;
      border: 0.555419px solid #0188f7;
      border-radius: 4.44335px;
      padding: 5px;
    }

    .item-value-disabled {
      background-color: white;
    }
  }
}

.config-item-active {
  background: #dcedff;
  border: 1px solid #0188f7;
  box-shadow: 0px 0px 6.30845px #cce7f8;
}

.config-item-disabled {
  background: #eeeeee;
  border: 1px solid #e4e7ec;

  &:hover {
    background: #eeeeee;
    border: 1px solid #e4e7ec;
    box-shadow: none;
  }
}

.personalise {
  padding: 18px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;

  p {
    font-weight: 500;
    font-size: 13.1699px;
    line-height: 19px;
    color: #8a8a8a;
    white-space: nowrap;
  }

  hr {
    width: 100%;
    border: 0.5px solid #e4e7ec;
  }
}

.personalise-content {
  margin-top: 10px;
  margin-bottom: 30px;

  .config-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    span {
      font-weight: 500;
      font-size: 13.1699px;
      line-height: 19px;
      color: #344054;
    }
  }

  .config-select {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 12px;
    width: 100%;

    .slider-container {
      width: 70%;

      .ant-slider-mark {
        top: -22px;

        .ant-slider-mark-text {
          &:last-child {
            transform: translateX(-100%) !important;
          }

          &:first-child {
            transform: translateX(0%) !important;
          }
        }
      }

      .ant-slider {
        margin-bottom: 0;
      }
    }

    .config-select-info {
      font-weight: 500;
      font-size: 13.1699px;
      line-height: 19px;
      color: #344054;
      padding: 10px;
      border: 1px solid #e4e7ec;
      border-radius: 8px;
    }
  }
}

.grayscale-effect {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  pointer-events: none;
}