.quota-group-drawer {
  .drawer-group {
    position: relative;
    &-close-icon {
      position: absolute;
      top: 0;
      right: 0;
      width: fit-content;
      cursor: pointer;
      svg {
        width: 17.611px;
        height: 17.611px;
        path {
          stroke: #177c9a;
        }
      }
    }
    &-title {
      text-align: center;
      padding: 15px 0;
      p:first-child {
        color: var(--Primary-Colors-Primary-800, #177c9a);
        text-align: center;
        font-size: 16.144px;
        font-style: normal;
        font-weight: 600;
        line-height: 25.113px;
      }
      p:last-child {
        color: var(--Gray-600, #475467);
        text-align: center;
        font-size: 12.556px;
        font-style: normal;
        font-weight: 400;
        line-height: 17.938px; /* 142.857% */
      }
    }
    &-form {
      form {
        .s_input {
          label {
            display: block;
            margin-bottom: 8px;
            color: var(--Gray-700, #344054);
            font-size: 12.556px;
            font-style: normal;
            font-weight: 500;
            line-height: 17.938px;
          }
        }
      }
      &-users {
        margin-bottom: 16px;
        .users-search {
          .ant-select {
            height: 44px;
            width: 100%;
            border-radius: 8px;
            border: 1px solid var(--Gray-300, #d0d5dd);
            background: var(--Base-White, #fff);
          }
        }
      }
      .selected-users {
        display: flex;
        align-items: center;
        gap: 6px;
        flex-wrap: wrap;
        margin-top: 10px;
        .selected-user-item {
          border-radius: 27.193px;
          background: var(--Primary-Colors-Gray-100, #f2f4f7);
          display: flex;
          justify-content: center;
          align-items: center;
          column-gap: 8px;
          padding: 3.5px 8px 3.5px 5px;
          width: fit-content;
          color: var(--Primary-Colors-Gray-900, #101828);
          font-size: 12.281px;
          font-style: normal;
          font-weight: 500;
          line-height: 17.544px;
          text-transform: capitalize;
          svg {
            cursor: pointer;
          }
        }
      }
    }
    &-quota {
      .title {
        color: var(--Primary-Colors-Primary-800, #177c9a);
        font-size: 16.144px;
        font-style: normal;
        font-weight: 600;
        line-height: 25.113px;
      }
    }
    &-resources {
      padding: 13px 16px;
      border-radius: 10px;
      border: 1px solid #d0eef1;
      background: #f8fbff;
      margin: 19px 0;
      & > div {
        display: flex;
        align-items: center;
        column-gap: 35px;
      }
    }
    &-quota-setting {
      margin: 19px 0 15px;
      .slider-antd {
        margin-bottom: 15px;
      }
      .slider-content {
        column-gap: 20px;
        .active-value {
          font-size: 11px;
        }
      }
    }
    &-label {
      color: var(--Gray-700, #344054);
      font-size: 12.556px;
      font-style: normal;
      font-weight: 500;
      line-height: 17.938px;
      margin-bottom: 8px;
      display: block;
    }
    &-switch {
      display: flex;
      align-items: center;
      column-gap: 15px;
      margin-top: 10px;
    }

    .confirmation {
      display: flex;
      justify-content: center;
      column-gap: 17px;
      margin-top: 50px;
      button {
        border-radius: 7.175px;
        font-size: 14.35px;
        font-style: normal;
        font-weight: 600;
        line-height: 21.525px;
        font-family: inherit;
        width: 189px;
        padding: 9px 16.1px;
        outline: none;
        box-shadow: 0px 0.897px 1.794px 0px rgba(16, 24, 40, 0.05);
        cursor: pointer;
      }
      &-confirm {
        background: var(--Prim-Blue, #0188f7);
        border: none;
        color: var(--Base-White, #fff);
      }
      &-cancel {
        border: 1px solid var(--Prim-Blue, #0188f7);
        background: #fff;
        color: var(--Prim-Blue, #0188f7);
      }
    }
  }
}

.desktop-group-item {
  color: var(--Primary-Colors-Gray-700, #344054);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  border-radius: 17.455px;
  border: 1.091px solid var(--Primary-Colors-Gray-700, #344054);
  width: fit-content;
  padding: 0px 7.636px;
  position: relative;
  padding-left: 15px;
  margin-right: 6px;
  &::before {
    content: "";
    width: 6px;
    height: 6px;
    background-color: #344054;
    position: absolute;
    left: 5px;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
  }
}
