@use "./assets/styles/base/base";
// Layouts
@use "./layouts/MainLayout";
@use "./layouts/GuestLayout";
@use "./layouts/AdminLayout";

// Components
@use "./components/Sidebar";
@use "./components/Header";
@use "./components/Menu";
@use "./components/Progress";
@use "./components/HamburgerMenuTrigger";
@use "./components/Backdrop";
@use "./components/NotFound";
@use "./components/NoPermissionView";
@use "./components/Navigation";
@use "./components/Input";
@use "./components/Status";
@use "./components/FilePreview";
@use "./components/FileTransfer";
@use "./components/Feedback";
@use "./components/ScreenShot";
@use "./components/ScreenRecording";
@use "./components/SnapshotList";

@use "./components/InputFields";
@use "./components/Modals";
@use "./components/SplashScreen";
@use "./components/LoaderComponent/LoaderComponent";

//pages
@use "./features/Authentication/login";
@use "./features/Authentication/components/Card";
@use "./features/Authentication";
@use "./features/Authentication/register";
@use "./features/Billing";
@use "./features/Users/Users";
@use "./features/NetworkPolicy";
@use "./features/QuotaManagment";
@use "./features/Notifications";

//profile
@use "./features/Settings";
@use "./layouts/SettingLayout";

//desktops
@use "./features/Desktops";
@use "./features/Desktops/components/DesktopsHeader";
@use "./features/Desktops/components/DesktopsCategories";
@use "./features/Desktops/components/DesktopCard";
@use "./features/Desktops/components/ActiveDesktop";
@use "./features/Desktops/components/DesktopTemplates";

//admin
@use "./components/Table";
@use "./components/ViewTitle";
@use "./components/Pagination";
@use "./features/UserGroup";
@use "./features/ImageGroup";
@use "./components/Tabs";
@use "./features/Ldap";
@use "./features/Offers";
@use "./features/Dashboard";
@use "./features/LtiConfig";

//vnc viewer
@use "./features/VNCViewer";
@use "./components/Panel";

//rdp
@use "./features/RDPViewer/RDPViewer";
@use "./features/MacVncViewer/MacVncViewer";

//lti 
@use "./features/LtiAuthentication";

.view_container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100vh;
  background-color: white;
}

.stream-quality-header {
  width: 400px;
}