.invite-user-list {
  .modal_title {
    .label {
      display: flex;
      width: 100%;
      align-items: center;
      flex-direction: column;
      row-gap: 14px;
      p {
        color: var(--Prim-Blue, #0188f7);
        font-size: 18px;
        font-weight: 600;
      }
      img {
        width: 70px;
      }
    }
  }
  &-content {
    margin: 15px 0;
    .invited-user {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 10px;
      background: #f4faff;
      padding: 8px 16px;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      p {
        color: var(--font, #344054);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-left: 10px;
        overflow: hidden;
        flex: 1;
        text-overflow: ellipsis;
      }
      .user-tag {
        border-radius: 28px;
        border: 1px dashed transparent;
        font-size: 12px;
        font-weight: 400;
        padding: 4px 12px;
      }
    }
  }
  .invite-user-info {
    text-align: center;
    color: var(--gray-600, #475467);
    font-size: 14px;
    font-weight: 400;
  }
  .action_form {
    width: 100%;
    margin-top: 20px;
    text-align: right;
    .ant-btn {
      border-radius: 8px;
      background: var(--Prim-Blue, #0188f7);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      width: 100%;
      padding: 10px 18px;
      color: var(--base-white, #fff);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      height: fit-content;
    }
  }
}
