.form_feedback {
  .help-message {
    width: auto;
    height: auto;
    line-height: 150%;
    margin: 0 0 16px 0;
    padding: 0;
    text-transform: none;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
  }
  .rate {
    display: flex;
    align-content: center;
    justify-content: center;
    margin-bottom: 15px;
    .anticon svg {
      width: 40px;
      height: 40px;
    }
  }
  .media-container {
    .media-item {
      border: 1px solid #dfe6ec;
      border-radius: 8px;
      background: #fff;
      margin-bottom: 10px;
      height: 50px;
      padding: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 13px;
      color: #6080a0;
      &-preview {
        margin-right: 10px;
        width: 30px;
        height: 30px;
        overflow: hidden;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        img {
          width: 30px;
          height: 30px;
        }
      }
      &-s1,
      &-s2 {
        display: flex;
        align-items: center;
      }
      &-s2 {
        column-gap: 5px;
        span {
          display: flex;
          align-items: center;
          cursor: pointer;
          svg {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
}

//test

.drag {
  position: absolute;
  cursor: move;
  overflow: auto;
  top: 20px;
  left: 50px;
  max-height: 1080px;
  max-width: 1920px;
  background-color: transparent;
  border: 8px solid #d204e5;
  text-align: center;
  min-height: 250px;
  min-width: 400px;
  border-top: none;
}

.drag-header {
  padding: 5px;
  cursor: move;
  z-index: 10;
  background-color: #d204e5;
  color: #fff;
}

.sizes {
  display: flex;
  align-content: center;
  justify-content: center;
  column-gap: 15px;
}

.sizes div {
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  color: #fff;
  padding: 10px 15px;
  font-size: 17px;
  font-weight: 600;
  cursor: pointer;
  background: #372c41;
}

/* resize buttons */
.drag .resizer-right {
  width: 5px;
  height: 100%;
  background: transparent;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: e-resize;
}

.drag .resizer-bottom {
  width: 100%;
  height: 5px;
  background: transparent;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: n-resize;
}

.drag .resizer-both {
  width: 5px;
  height: 5px;
  background: transparent;
  z-index: 10;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: nw-resize;
}
