.responsive_container {
  position: relative;
  display: flex;
  flex-grow: 1;
  overflow: auto;

  & > .table_container {
    // position: absolute;
    min-width: 800px;
    display: flex;
    flex-direction: column;
    width: 100%;

    & > * {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 34px;
      padding: 0 25px 0 0px;
      height: 72px;
      border-bottom: 1px solid #e7e7e7;

      & > * {
        display: flex;
        // white-space: nowrap;
        // flex-grow: 1;
        // flex-basis: 0;
        justify-content: flex-start;
        width: 25%;
      }
      & > :last-child {
        justify-content: flex-end;
      }
      & > :first-child {
        justify-content: flex-start;
        // width: 35% !important;

        @media only screen and (max-width: 600px) {
          // width: 50% !important;
        }
      }
      &.table__header {
        background: #f2fafe;
        height: 50px;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #101828;
        border-bottom: none;
        position: sticky;
        width: 100%;
        top: 0;
        z-index: 2;
        .table_item {
          display: flex;
          align-items: center;
          gap: 3px;
          cursor: pointer;
        }
        img {
          width: 12px;
        }

        // @media only screen and (max-width: 800px) {
        //   .status_c {
        //     margin-left: 40px;
        //     justify-content: flex-end;
        //   }
        // }
        // @media only screen and (max-width: 1800px) {
        //   .status_c {
        //     margin-left: 35px;
        //   }
        // }
      }
    }
  }
}
