.image_group_form {
  .images {
    margin-top: 30px;
    padding: 0 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 0 23px;
    max-height: 270px;
    overflow: auto;
    &_item {
      display: flex;
      column-gap: 10px;
      &-label {
        text-align: center;
        img {
          width: 35px;
          height: 35px;
        }
        p {
          font-weight: 500;
          font-size: 10px;
          inline-size: 50px;
          overflow-wrap: break-word;
          text-align: center;
        }
      }
    }
  }
  .select_all {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px;
    border-radius: 8px;
    border: 1px solid #e2e5e9;
    margin-top: 15px;

    .title_item {
      display: flex;
      align-items: center;
      gap: 9px;
      p {
        color: var(--gray-700, #344054);
        font-size: 12.556px;
        font-style: normal;
        font-weight: 500;
        line-height: 17.938px; /* 142.857% */
      }
    }
    .switch {
      display: flex;
      align-items: center;
      gap: 8px;
      .status-active {
        color: var(--Prim-Blue, #0188f7);
        font-size: 13.161px;
        font-style: normal;
        font-weight: 500;
        line-height: 18.801px; /* 142.857% */
      }
      .status-inactive {
        color: #afb4bd;
        font-size: 13.161px;
        font-style: normal;
        font-weight: 500;
        line-height: 18.801px; /* 142.857% */
      }
    }
  }
  .ant-checkbox-wrapper {
    span {
      padding: 0 !important;
    }
  }
}
