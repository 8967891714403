// @use "../../../../assets/styles/abstracts" as *;

.card-auth {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 1089px) {
    padding: 0px 16px;
  }

  .card_auth_content {
    text-align: center;
    width: 100%;
    max-width: 380px;

    & > p {
      font-weight: 600;
      font-size: 36px;
      color: #003b71;
      margin-bottom: 15px;
      margin-top: 70px;
    }
    .card_auth__desc {
      font-weight: 400;
      font-size: 16px;
      color: #999999;
      margin-bottom: 18px;
    }
  }
}
