.view-container {
  height: 100%;

  .rdp-view {
    overflow: hidden;
    cursor: none;
  }

  .state_session {
    font-weight: 500;

    p::first-letter {
      text-transform: uppercase;
    }

    p {
      text-transform: lowercase;
    }
  }
}