.template-form {


  &_info {
    text-align: center;
    margin-bottom: 10px;



    img {
      width: 85px;
    }

    p {
      font-weight: 500;
      font-size: 14px;
      color: #344054;
    }
  }

  &_config {
    display: flex;
    flex-direction: column;
    row-gap: 29px;

    &-checkbox {
      width: fit-content;
      display: flex;
      align-items: center;
      column-gap: 10px;

      label::after {
        height: 16px;
      }

      label {
        height: 16px;
        display: flex !important;
      }
    }

    .config {
      font-weight: 500;
      font-size: 14px;

      &-content {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px;
      }

      p {
        color: #344054;
        margin-bottom: 6px;
      }

      span:not(span > span) {
        cursor: pointer;
        padding: 0 14px;
        border: 1px solid #d0d5dd;
        border-radius: 8px;
        height: 40px;
        display: flex;
        align-items: center;
        column-gap: 5px;

        span {
          font-weight: 600;
          font-size: 18px;
        }
      }
    }

    .active-config {
      border: 1px solid #157deb !important;
    }
  }

  form .action_form {
    margin-top: 25px;

    .dashboard-cancel-btn {
      width: 210px;
      height: 44px;
      background: #ffffff;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      color: #0188f7;
      transition: all 300ms ease;
      border: 1px solid #0188f7;

      &:hover {
        background-color: #0188f7;
        color: #ffffff;
      }
    }

    .dashboard-confirm-btn {
      width: 210px;
      height: 44px;
      background: #0188f7;
      border: 1px solid #0188f7;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      color: #ffffff;
      transition: all 300ms ease;

      &:hover {
        background: #0188f7;
        filter: brightness(90%);
      }
    }
  }

  .gpu {
    margin-top: 20px;
  }

  .template-form_config-checkbox {
    .ant-checkbox-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

    }



  }

  // .blured-content {
  //   // background-color: red;
  //   width: 100%;
  //   height: 100%;
  //   padding: 20px;
  //   border-radius: 15px;
  //   filter: blur(2px);
  //   transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  //   opacity: 1;
  //   pointer-events: none;
  //   user-select: none;
  //   /* Disable pointer events on the container */

  // }
}