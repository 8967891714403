.feedback {
  position: absolute;
  bottom: 20%;
  right: 0;
  background-color: #0188f7;
  transform: rotate(-90deg) translate(50%, -50%);
  transform-origin: 100% 50%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: white;
  z-index: 1000;
  padding: 5px 20px;
  cursor: pointer;
  -webkit-transform: rotate(-90deg) translate(50%, -50%);
  -moz-transform: rotate(-90deg) translate(50%, -50%);
  -ms-transform: rotate(-90deg) translate(50%, -50%);
  -o-transform: rotate(-90deg) translate(50%, -50%);
}
