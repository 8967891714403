.vnc-sidenav {
  width: 250px;
  padding: 35px;
  border-radius: 0px 12px 12px 0px;
  position: absolute;
  top: 50%;
  left: 0;
  background: #09020280;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  border-left-color: transparent;
  z-index: 20;
  border-radius: 0px 10px 10px 0px;
  background: #f0fafb;
  box-shadow: 4px 0px 0px 0px #0188f7;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: 0px 12px 12px 0px;
    -webkit-border-radius: 0px 12px 12px 0px;
    -moz-border-radius: 0px 12px 12px 0px;
    -ms-border-radius: 0px 12px 12px 0px;
    -o-border-radius: 0px 12px 12px 0px;
  }
  .active-item {
    color: #fff;
    background-color: #0284f8;
    svg {
      path {
        fill: #fff;
      }
    }
  }
  &-toggle-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(100%, -50%);
    -webkit-transform: translate(100%, -50%);
    -moz-transform: translate(100%, -50%);
    -ms-transform: translate(100%, -50%);
    -o-transform: translate(100%, -50%);
    transition: all 0.1s;
    -webkit-transition: all 0.1s;
    -moz-transition: all 0.1s;
    -ms-transition: all 0.1s;
    -o-transition: all 0.1s;
    border-left-color: transparent;
    background-color: #015ba3d2;
    color: #fff;
    cursor: pointer;
    &:hover {
      background-color: #005ba5;
    }
    border-radius: 0px 10px 10px 0px;
    background: #0188f7;
    width: 30px;
    height: 44px;
    box-shadow: 0px 4px 4.4px 0px rgba(186, 225, 249, 0.9);
  }
  &-item {
    display: flex;
    align-items: center;
    column-gap: 15px;
    height: 45px;
    padding: 0 10.33px;
    position: relative;
    z-index: 2;
    cursor: pointer;
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    color: #0284f8;
    font-size: 14.504px;
    font-style: normal;
    white-space: nowrap;
    font-weight: 400;
    line-height: normal;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 2.785px 9.748px 0px #02bbf024;
    &:not(:last-child) {
      margin-bottom: 12px;
    }
    svg {
      min-width: 25.218px;
      min-height: 25.218px;
    }
  }
  .delete-session-item {
    &:hover {
      background-color: #fb4b4ba9;
    }
  }
}

.vnc-sidenav-hide {
  left: -250px;
  box-shadow: none;
}

.vnc-sidenav-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 11;
}

.copy-paste-popover {
  width: 200px;
  max-height: 250px;
  min-height: 150px;
  height: 250px;

  p {
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    white-space: nowrap;
  }

  .textarea {
    margin-top: 10px;
    width: 100%;
    height: 80%;
    overflow: auto;
    div {
      font-size: 13px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
      padding: 2px 4px;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
      color: #5e7693;
      font-weight: 500;
      &:not(:last-child) {
        margin-bottom: 2px;
      }
      &:hover {
        color: #fff;
        background-color: #0285f8c4;
      }
    }
  }
}
