.cpu-ram-disk-usage-component {
  margin-top: 24px;
  width: 100%;
  min-height: 225px;
  background-color: rgb(255, 255, 255);
  color: rgb(33, 43, 54);
  overflow: hidden;
  box-shadow:
    rgba(145, 158, 171, 0.2) 0px 0px 2px 0px,
    rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
  border-radius: 16px;
  padding: 24px;
  display: flex;
  gap: 12px;

  @media only screen and (max-width: 550px) {
    flex-wrap: wrap;
  }

  .cpu-ram-disk-usage-component-divider {
    height: auto !important;
  }

  .cpu-ram-disk-usage-component-info {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 22px;
    flex-wrap: wrap;
  }

  .cpu-ram-disk-usage-component-info-shart {
    .cpu-ram-disk-usage-component-info-shart-title {
      font-size: 18px;
      font-weight: 700;
      color: #212b36;
    }

    .cpu-ram-disk-usage-component-info-shart-subtitle {
      font-size: 14px;
      font-weight: 400;
      color: #212b36;
    }
  }

  .ant-progress-text {
    font-size: 18px !important;
    font-weight: 500;
  }
}
