.config-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 16px;
    padding: 20px 0;
    // max-width: 800px;
    margin: 0 auto;
    width: 100%;

    .config-card {
        .config-content {
            display: flex;
            flex-direction: column;
            gap: 8px;
            align-items: start;

            strong {
                color: #1d1d1d;
            }

            .ant-typography {
                color: #595959;
                font-size: 14px;
            }

            .ant-btn-primary {
                margin-top: 8px;
            }
        }
    }
}

.config_form {
    width: 100% !important;
    margin-bottom: 30px;

    form {
        width: 100%;

        input {
            width: 100%;
        }
    }
}

.config-versions {
    .lti_select_version {
        width: 100%;
        min-height: 44px;
        margin-bottom: 20px;
    }

    h3 {
        margin-bottom: 20px;

    }
}