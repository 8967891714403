.dashboard-usage-per-user {
  margin-top: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;

  @media only screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
  }

  .dashboard-usage-per-user-card {
    width: 100%;
    min-height: 250px;
    background-color: rgb(255, 255, 255);
    color: rgb(33, 43, 54);
    overflow: hidden;
    box-shadow:
      rgba(145, 158, 171, 0.2) 0px 0px 2px 0px,
      rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
    border-radius: 16px;
    padding: 24px;

    .dashboard-usage-per-user-card-header {
      margin-bottom: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 22px;

      .dashboard-usage-per-user-card-title {
        font-weight: 700;
        line-height: 1.5;
        font-size: 17px;
        font-family: "Public Sans", sans-serif;
        text-wrap: nowrap;
      }
    }
  }
}

.dashboard-usage-per-user-text {
  text-wrap: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
}
