.form {
  &__switchBox {
    margin-top: 20px;
    display: flex;
    align-items: center;
    column-gap: 8px;
    font-weight: 500;
    font-size: 14px;
    color: #344054;
  }
}

.image_form {
  &-list {
    &-container {
      & > div {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        column-gap: 10px;
      }
    }
    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: calc(50% - 10px);
      .s_input {
        flex: 1;
      }
      #error-valid {
        input {
          border: 1px solid red;
        }
      }
      .default-config {
        .s_input {
          input {
            border: 1px solid rgb(0, 94, 255);
          }
        }
      }
    }
  }
  .add-button {
    width: 50px;
    height: 50px;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    background-color: #ffffff;
    cursor: pointer;
    font-size: 24px;
    color: #344054;
    margin-bottom: 8px;
  }
  &-select {
    & > div {
      height: 44px !important;
      span:last-child {
        display: flex;
        align-items: center;
      }
    }
  }
  &-config-default {
    margin-bottom: 8px;
  }
  .categories {
    & > div {
      margin-bottom: 0;
      font-size: 14px;
    }
    .categories-error {
      border: 1px solid red;
    }
  }

  .dynamic-input-container {
    padding: 12px 0;
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    align-items: center;
    gap: 12px;

    .input-container {
      padding: 6px;
      width: 94px;
      min-height: 94px;
      border: 1px dashed #d2d2d7;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      cursor: pointer;
      position: relative;
      overflow: hidden;

      .s_input {
        display: flex;
        justify-content: center;
        align-items: center;

        input {
          padding: 0;
        }
      }

      .dynamic-input-menu {
        display: flex;
        justify-content: center;
        padding: 3px;
        gap: 8px;
        z-index: 99;
        position: absolute;
        top: 1px;
        right: 1px;
        display: none;
      }

      input {
        width: 100%;
        border: 0;
        outline: none;
        box-shadow: none;
        text-align: center;
        font-size: 14px;
      }

      &:hover {
        border: 1px dashed #0088f7;

        .dynamic-input-menu {
          display: flex;
        }
      }
    }

    .plus-btn-container {
      padding: 6px;
      width: 94px;
      height: 94px;
      border: 1px dashed #d2d2d7;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 25px;
      color: #a7a7aa;
      cursor: pointer;

      &:hover {
        color: #0469bc90;
      }
    }
  }

  .dynamic-form-block {
    border-bottom: 1px solid #eee;
    padding-bottom: 12px;
    margin-bottom: 12px;
  }
}
