.section_upgrade {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  padding: 80px;
  height: calc(100vh - 100px);

  .card {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, rgba(104, 205, 249, 0.2), rgba(7, 141, 238, 0.2))
      rgb(255, 255, 255);
    border-radius: 16px;
    // max-width: 600px;
    width: 100%;
    height: 100%;
    gap: 30px;

    img {
      width: 200px;
    }

    .btn_plan {
      display: inline-flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      position: relative;
      box-sizing: border-box;
      -webkit-tap-highlight-color: transparent;
      outline: 0px;
      border: 0px currentcolor;
      cursor: pointer;
      user-select: none;
      vertical-align: middle;
      appearance: none;
      text-decoration: none;
      font-weight: 700;
      line-height: 1.71429;
      text-transform: unset;
      font-family: "Public Sans", sans-serif;
      min-width: 64px;
      padding: 8px 16px;
      border-radius: 8px;
      transition:
        background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      box-shadow: none;
      height: 48px;
      font-size: 15px;
      color: rgb(255, 255, 255);
      // background-color: rgb(33, 43, 54);
      background-color: #0e88f7;
    }

    p {
      color: #0b1a27;
      text-align: center;
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
