.desktops_page {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  background-color: #fff;

  .quota-groups {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px 0 60px;
    &-content {
      display: flex;
      align-items: center;
      border-radius: 88px;
      background: #fff;
      box-shadow: 0px 3px 21.4px 0px rgba(204, 231, 248, 0.71);
      p {
        color: #5e7693;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        padding: 10px;
      }
    }
    &-dropdown {
      padding: 10px;
      border-radius: 61px;
      background: var(--Prim-Blue, #0188f7);
      color: #fff;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      cursor: pointer;
      svg {
        margin-left: 10px;
      }
    }
  }
}

.spinner {
  position: fixed;
  top: 50%;
  left: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  &__content {
    cursor: pointer;
    &:hover {
      color: red;
    }
  }
}

:root {
  --wheel-font: "Lato", "Quicksand", sans-serif;
  --wheel-size: 400px;
  --wheel-slice-spacing: 50px;
  --wheel-border-size: 5px;
  --wheel-color: #832a4b;
  --neutral-color: white;
  --PI: 3.14159265358979;
  --nb-item: 1;
  --item-nb: 0;
  --selected-item: 0;
  --nb-turn: 5;
  --spinning-duration: 4s;
  --reset-duration: 0.25s;
}

.wheel-container {
  display: block;
  position: relative;
  box-sizing: content-box;
  padding: 3px;
  margin: auto;
  background-color: var(--neutral-color);
  border-radius: 50%;
  user-select: none;
}

.wheel-container::before,
.wheel-container::after {
  content: "";
  display: block;
  position: absolute;
  height: 0;
  width: 0;
  top: 50%;
  /* transform: translateY(-50%); */
  z-index: 2;
  /* border: solid transparent 20px; */
  /* border-left-width: 0; */
}

.wheel-container::before {
  right: 0px;
  border-right-color: var(--wheel-color);
  box-sizing: border-box;
}

.wheel-container::after {
  right: -5px;
  border-right-color: var(--neutral-color);
  box-sizing: border-box;
}

.wheel {
  display: block;
  position: relative;
  box-sizing: content-box;
  margin: auto;
  width: var(--wheel-size);
  height: var(--wheel-size);
  overflow: hidden;
  border-radius: 50%;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  transition: transform var(--reset-duration);
  transform: rotate(0deg);
  cursor: pointer;
}

.wheel.spinning {
  transition: transform var(--spinning-duration);
  transform: rotate(
    calc(var(--nb-turn) * 360deg + (-360deg * var(--selected-item) / var(--nb-item, 1)))
  );
}

/* center button */
.wheel_center {
  display: block;
  position: absolute;
  color: rgba(0, 0, 0, 0.87);
  width: 142px;
  height: 142px;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #d9d9d9;
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #f3f3f3;
    width: 85%;
    height: 85%;
    border-radius: 50%;
  }
}

.wheel-item {
  display: block;
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  left: 50%;
  width: 50%;
  height: 162px;
  transform-origin: center left;
  transform: translateY(-50%) rotate(calc(var(--item-nb) * (360deg / var(--nb-item, 1))));
  color: #000000;
  padding-left: 80px;
  font-family: var(--wheel-font);
  &:hover &-bihavior {
    border-right: solid #5f6368 calc(var(--wheel-size) / 2);
    opacity: 1;
    color: #ffff;
  }
  &:hover {
    color: #ffffff;
  }
  display: flex;
  align-items: center;
  &-bihavior {
    display: block;
    position: absolute;
    box-sizing: border-box;
    z-index: -1;
    width: 0;
    height: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-left: 0px;
    opacity: 0.25;
    --slice-max-width: calc(var(--PI) * var(--wheel-size) + var(--wheel-size) / 2);
    --slice-width: 162px;
    border: solid transparent calc(var(--slice-width) / 2);
    border-left: solid transparent 0;
    border-right: solid #e9e9e9 calc(var(--wheel-size) / 2);
    box-sizing: border-box;
  }
}

.container {
  position: relative;
  width: 200px;
  height: 200px;
  background-color: lightgray;
}

.triangle-up {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-bottom: 100px solid red;
  z-index: 1;
  cursor: pointer;
}

.triangle-up::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.clicked::before {
  background-color: rgba(0, 0, 0, 0.5);
}

.center-spinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
