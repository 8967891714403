.head_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    color: var(--gray-600, #475467);
    text-align: center;
    font-size: 12.556px;
    font-style: normal;
    font-weight: 400;
    line-height: 17.938px; /* 142.857% */
  }
}
