.user-consumption-modal {
  .MuiPaper-root {
    padding: 29px 40px !important;
    max-width: 1200px !important;
  }
  .user-consumption-warning {
    padding: 8px 17px;
    background: #f0fafb;
    display: flex;
    align-items: center;
    color: var(--font, #344054);
    text-align: center;
    font-size: 14.805px;
    font-style: normal;
    font-weight: 500;
    line-height: 21.534px; /* 145.458% */
    column-gap: 10px;
    margin-left: 30px;
    margin-right: 30px;
    white-space: nowrap;
  }
  .user-consumption-section3 {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    p {
      color: var(--Primary-Colors-Gray-500, #667085);
      font-size: 23.759px;
      font-style: normal;
      font-weight: 400;
      line-height: 26.822px;
      text-transform: capitalize;
    }
  }
  .user-consumption-section4 {
    padding: 12.617px 14.72px;
    display: flex;
    justify-content: space-between;
    column-gap: 34px;

    .drawer-group-quota-setting {
      width: calc(50% - 17px);
      margin: 0;

      .sliders-container {
        padding: 12.617px 14.72px;
        border-radius: 10px;
        border: 1px solid var(--stroke, #e4e7ec);
        background: #fbffff;
      }
    }

    .drawer-group-quota-table {
      width: calc(50% - 17px);
      .users-search-input {
        margin-bottom: 15px;
      }
    }

    .slider-antd {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 30px;
      padding: 10px 0;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      .slider-label {
        white-space: nowrap;
      }
      .slider-content {
        width: 73%;
        .active-value {
          border-radius: 4.966px;
          border: 0.621px solid var(--Prim-Blue, #0188f7);
          color: var(--font, #344054);
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 12.416px; /* 88.683% */
        }
      }
    }

    .active-user {
      background: #fff5f5;
    }
  }
  .confirmation {
    margin-top: 0 !important;
    button {
      flex: 1;
      padding: 10px !important;
      font-size: 16px !important;
      font-weight: 600 !important;
    }
  }
  .ta-modal-content-scroll {
    & > div:not(:last-child) {
      margin-bottom: 34px;
    }
  }
}
