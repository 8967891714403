.section_upload {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 20px;
  margin-bottom: 40px;
  .dragger {
    flex: 1;
    // max-width: 356px;
    height: 74px;
    padding: 0 !important;
    // border: 1px dashed #e4e7ec;
    border: 1px dashed #21afda;
    border-radius: 12px;
    background: #e5f4fd;

    .dragger-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 12px 24px;

      .dragger-content-text {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 6px;

        .upload-text {
          font-size: 14px;
          color: #344054;

          span {
            font-size: 12px;
            color: #475467;
          }
        }

        .upload-hint {
          font-size: 12px;
          color: #475467;
        }
      }
    }
  }
  .ant-upload {
    padding: 0 !important;
  }
  //   .ant-upload-drag {
  //     // border: none;
  //   }

  // .upload-hint {
  //   font-weight: 400;
  //   font-size: 12px;
  //   line-height: 18px;
  //   text-align: center;
  //   color: #475467;
  // }
  // .upload-text {
  //   font-weight: 600;
  //   font-size: 14px;
  //   line-height: 20px;
  //   color: #344054;
  // }
  .avatar {
    background: #e4e7ec;
    color: #98a2b3;
  }
}

@media only screen and (max-width: 620px) {
  .section_upload {
    .dragger {
      .dragger-content {
        justify-content: center;

        .dragger-content-text {
          display: none;
        }
      }
    }
  }
}
