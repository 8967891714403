.btn_auth {
  width: 100%;
  height: 44px;
  background: #667085;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  border: none;
  color: white;
  cursor: pointer;
}
