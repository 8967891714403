.request-data-section {
  .content-request-data {
    .top-section-data {
      display: grid;
      grid-gap: 10px 20px;
      grid-template-columns: 1fr 1fr;
    }

    label {
      font-weight: 400;
      font-size: 14px;
      color: #344054;
      margin-bottom: 6px;
    }

    .request-type {
      margin-bottom: 20px;

      .types-of-request {
        display: flex;
        gap: 20px;
        flex-wrap: auto;
      }

      .request-type-1 {
        cursor: pointer;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        border-radius: 7.175px;
        border: 0.897px solid var(--Gray-300, #d0d5dd);
        background: var(--Base-White, #fff);
        box-shadow: 0px 0.897px 1.794px 0px rgba(16, 24, 40, 0.05);

        padding: 12px 12px;
        user-select: none;

        .ant-checkbox-wrapper {
          margin-bottom: 0;
        }

        .title-type {
          font-size: 15px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
        }

        .ant-checkbox {
          border-radius: 50% !important;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            border-radius: 50% !important;
          }
        }

        .ant-checkbox-inner {
          border-radius: 50%;
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          // background-color: #344054;

          &:hover {
            border-radius: 50% !important;
          }
        }

        .ant-checkbox-checked {
          border-radius: 50% !important;

          &::after {
            border-radius: 50% !important;
          }

          .ant-checkbox-inner {
            border-radius: 50%;
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;

            &::after {
              display: flex;
              align-items: center;
              justify-content: center;
              margin-left: 2px;
            }

            &:hover {
              border-radius: 50% !important;
            }
          }
        }
      }
    }
  }

  .actions-modal {
    display: flex;
    gap: 30px;
    justify-content: flex-start;
    // background-color: #0188f7;
    width: 100%;
    background: #fff;

    .btn-privacy {
      display: flex;
      width: 166px;
      height: 44px;
      padding: 10px 24px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      border-radius: 8px;
    }

    .cancel-btn {
      border: 1px solid var(--Prim-Blue, #0188f7);
      background: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;

      /* 150% */
      span {
        color: var(--Prim-Blue, #0188f7);
      }
    }

    .save-btn {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      border: 1px solid var(--Prim-Blue, #0188f7);
      background: var(--Prim-Blue, #0188f7);

      span {
        color: #fff;
      }

      &:hover {
        background: var(--Prim-Blue, #0188f7);
      }
    }
  }
}

//segmented
