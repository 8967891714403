.screenshot {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 5px solid #0188f7;
  z-index: 1000;
  background-color: #eeeeee57;
  canvas {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  &-cancel-button {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: #0188f7;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border-bottom-left-radius: 8px;
    z-index: 1000;
    cursor: pointer;
    svg {
      path {
        fill: #fff;
      }
    }
  }

  button {
    position: relative;
    z-index: 1000;
  }

  &-toolbar {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    background-color: #fff;
    padding: 0 12px 0 24px;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    border-radius: 25px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.478);
    &-button {
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 4px;
      border-radius: 8px;
      background-color: #fff;
      cursor: pointer;
      &:hover {
        background-color: #d1d1d1c7;
      }
    }
    .active-color {
      background-color: #d1d1d1c7;
    }
    &-color {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
    }
    &-confirm {
      background-color: #0188f7;
      color: #fff;
      font-size: 13px;
      font-weight: 500;
      padding: 4px 10px;
      border-radius: 15px;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      cursor: pointer;
    }
  }
}
.draggable {
  position: relative;
  z-index: 1000;
  background-color: white;
  width: fit-content;
  margin: 0 auto;
  // top: 0;
  // left: 50%;
}
