.file-preview-modal {
  .file-title {
    text-align: center;
  }
  .file-preview {
    text-align: center;
    max-width: 100%;
    max-height: 100%;
    img,
    svg,
    video {
      max-width: 100%;
      max-height: calc(95vh - 160px);
    }
  }
  & > div > div {
    max-width: 80% !important;
    max-height: 80vh !important;
    width: fit-content !important;
    min-width: 530px;
  }
  .error-message {
    line-height: 30px;
    font-size: 18px;
    color: red;
  }
}
