.snapshot-list-modal {
  .MuiPaper-root {
    padding: 30px 50px !important;
    width: 1300px !important;
    max-width: 1300px !important;
    min-height: 500px !important;
    max-height: 90vh !important;
    .title {
      color: var(--Primary-Colors-Primary-800, #177c9a);
      text-align: center;
      font-size: 16.144px;
      font-style: normal;
      font-weight: 600;
      line-height: 25.113px; /* 155.556% */
      text-align: center;
      width: 100%;
    }

    .ta-modal-content-scroll {
      margin-top: 20px;

      .tabs {
        display: flex;
        align-items: center;
        column-gap: 10px;
        margin-bottom: 15px;
        padding-left: 20px;
        span {
          cursor: pointer;
          font-size: 14px;
          letter-spacing: 0.8px;
          font-weight: 600;
          color: #667085;
          display: block;
          width: 50px;
          text-align: center;
          border-bottom: 2px solid transparent;
        }
        .active-tab {
          border-color: #177c9a;
        }
      }

      .list-view {
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        border: 1px solid var(--stroke, #e4e7ec);
        padding: 23px 34px;
        .snapshot-list-header {
          display: flex;
          align-items: center;
          div {
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
          }
          div:first-child {
            flex: 1;
          }
          div:not(:first-child) {
            width: 190px;
          }
        }
      }
      .snapshot-list {
        display: flex;
        flex-wrap: wrap;
        gap: 28px;
        .snapshot-item {
          width: 365px;
          height: 290px;
          border-radius: 12.982px;
          border: 1px solid #d0d5dd;
          background: #fff;
          padding: 14.281px 20.772px;
          &-col1 {
            display: flex;
            align-items: center;
            column-gap: 16px;
            margin-bottom: 9px;
          }

          &-name {
            color: var(--Gray-700, #344054);
            font-size: 19.474px;
            font-style: normal;
            font-weight: 500;
            line-height: 23.287px; /* 119.584% */
          }

          &-created {
            color: var(--font, #344054);
            font-size: 11.684px;
            font-style: normal;
            font-weight: 300;
            line-height: 22.07px; /* 188.889% */
          }

          &-user {
            color: var(--Primary-Colors-Gray-500, #667085);
            font-size: 10.451px;
            font-style: normal;
            font-weight: 400;
            line-height: 27.92px; /* 267.15% */
            margin-top: 7px;
            .ant-avatar {
              width: 22.07px;
              height: 22.07px;
              margin-right: 10px;
            }
          }

          &-image {
            .ant-avatar {
              width: 96.07px;
              height: 96.07px;
              border-radius: 7.231px;
              background: #e4e7ec;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 40px;
              color: #344054;
            }
          }

          &-description {
            color: var(--font, #344054);
            font-size: 12.982px;
            font-style: normal;
            font-weight: 300;
            line-height: 18.175px; /* 140% */
            padding: 11.684px 0px;
          }

          &-user-groups {
            padding: 14px 0;
            border-top: 1.298px solid var(--stroke, #e4e7ec);
            border-bottom: 1.298px solid var(--stroke, #e4e7ec);
          }

          &-status {
            margin-top: 13px;
            padding: 5.193px 12.982px;
            border-radius: 46.737px;
            font-size: 12.982px;
            font-style: normal;
            font-weight: 400;
            line-height: 18.175px; /* 140% */
            width: fit-content;
          }

          &-status.success {
            background: linear-gradient(161deg, #ceffdf 4.11%, #deffe5 87.41%);
            color: #206f13;
          }

          &-status.inprogress {
            background: linear-gradient(161deg, #ffce92b7 4.11%, #ffcc9cc3 87.41%);
            color: #ff8e03;
          }

          &-status.fail {
            border-radius: 46.737px;
            background: linear-gradient(161deg, #ffcece 4.11%, #fac7c7 87.41%);
            -webkit-border-radius: 46.737px;
            -moz-border-radius: 46.737px;
            -ms-border-radius: 46.737px;
            -o-border-radius: 46.737px;
            p {
              display: flex;
              align-items: center;
              column-gap: 10px;
              svg {
                cursor: pointer;
              }
            }
          }
        }
        .snapshot-item-list {
          display: flex;
          align-items: center;
          .col2 {
            width: 190px;
          }
          .col1 {
            display: flex;
            align-items: center;
            flex: 1;
            .snapshot-item-image {
              .ant-avatar {
                border-radius: 7.231px;
                background: #e4e7ec;
                width: 34.07px;
                height: 34.07px;
              }
            }
            .snapshot-item-name {
              color: var(--font, #344054);
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px; /* 150% */
              margin: 0 20px;
            }
            .snapshot-item-status {
              margin: 0;
            }
          }
          .snapshot-item-user {
            color: var(--font, #344054);
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 25.587px; /* 160.691% */
            .ant-avatar {
              width: 30px;
              height: 30px;
            }
          }
          .snapshot-item-user-groups {
            border: none;
            padding: 0;
          }
          .snapshot-item-created {
            color: var(--font, #344054);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
          }
        }
      }
    }
  }
  .no-data {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 70px;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
    img {
      width: 100px;
      margin-bottom: 20px;
    }
  }
}
