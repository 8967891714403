.plan {
  // max-height: 100% !important;

  .first_section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 26px;
    gap: 15px;
    .title {
      color: var(--font, #344054);
      font-size: 18px;
      display: flex;
      align-items: center;
      gap: 16px;
      font-weight: 500;
      line-height: 28px;
      margin-bottom: 10px;
    }
    .subtitle {
      color: var(--font, #344054);
      font-size: 14px;
      line-height: 20px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .amount_per_month {
      color: #000;
      font-size: 30px;
      font-weight: 500;
      line-height: 30px;
      // text-overflow: ellipsis;
      // overflow: hidden;
      text-align: flex-end;
      @media only screen and (max-width: 602px) {
        font-size: 20px;
        line-height: 18px;
      }

      span {
        font-size: 12px;
        white-space: nowrap;
      }
    }
  }
  .divider {
    height: 1px;
    width: 100%;
    background: var(--stroke, #e4e7ec);
    margin-bottom: 13px;
  }
  .action {
    // display: flex;
    // justify-content: bottom;
    // column-gap: 20px;
    // margin-bottom: 13px;
    // float: inline-end;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;

    .manage-plan {
      background-color: #1f8af4;
      color: #fff;
      text-wrap: nowrap;
    }
    // .cancel_plan {
    //   background-color: #e5f4fd;
    // }
  }

  .cancled-plan {
    margin-top: 12px;
    text-align: start;
    color: #9b9999;
    font-size: 14px;
  }
}

// .plan-card-mobile {
//   @media only screen and (max-width: 500px) {
//     // min-height: 320px !important;

//     .action {
//       width: 100%;
//       display: flex;
//       flex-direction: column;
//       justify-content: center;
//       align-items: center;
//       gap: 12px;

//       .cancel_plan,
//       .manage-plan,
//       .manage-plan-link {
//         width: 100%;
//       }
//     }
//   }
// }
