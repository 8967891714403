.circular-menu {
  position: fixed;
  bottom: 1em;
  right: 1em;
}

.circular-menu .floating-btn {
  display: block;
  width: 3.5em;
  height: 3.5em;
  border-radius: 50%;
  background: #d9d9d9;
  color: hsl(0, 0%, 100%);
  text-align: center;
  line-height: 3.9;
  cursor: pointer;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #def7fd;
  border: 1px solid #a4ddf8;
  box-shadow: 0px 7px 16px rgba(2, 128, 244, 0.16);
  border-radius: 29px;
}

.circular-menu.active .floating-btn {
  box-shadow: inset 0 0 3px hsla(0, 0%, 0%, 0.3);
  background: linear-gradient(180deg, #01d5eb 0%, #0166fc 100%);
  box-shadow: 0px 7px 16px rgba(2, 128, 244, 0.16);
  border-radius: 29px;

  .default_panel {
    filter: brightness(0) invert(1);
  }
}

.circular-menu .floating-btn:active {
  box-shadow: 0 4px 8px 0 hsla(0, 0%, 0%, 0.4);
  & > img {
    filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(134deg) brightness(124%)
      contrast(100%);
  }
}

.circular-menu .floating-btn i {
  font-size: 1.3em;
  transition: transform 0.2s;
}

.circular-menu.active .floating-btn i {
  transform: rotate(-45deg);
}

.circular-menu:after {
  display: block;
  content: " ";
  width: 3.5em;
  height: 3.5em;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -2;
  background: #d9d9d9;
  transition: all 0.3s ease;
  background-color: #def7fd;
}

.circular-menu.active:after {
  transform: scale3d(5.5, 5.5, 1);
  transition-timing-function: cubic-bezier(0.68, 1.55, 0.265, 1);
}

.circular-menu .items-wrapper {
  padding: 0;
  margin: 0;
}

.circular-menu .menu-item {
  position: absolute;
  top: 0.2em;
  right: 0.2em;
  z-index: -1;
  display: block;
  text-decoration: none;
  color: hsl(0, 0%, 100%);
  font-size: 1em;
  width: 3em;
  height: 3em;
  border-radius: 50%;
  text-align: center;
  line-height: 3;
  background-color: #aedce7;
  transition:
    transform 0.3s ease,
    background 0.2s ease;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circular-menu .menu-item:hover {
  background: linear-gradient(180deg, #01d5eb 0%, #0166fc 0%);
  img {
    filter: brightness(0) invert(1);
  }
}

.menu-item-active {
  background: linear-gradient(180deg, #01d5eb 0%, #0166fc 0%);
  img {
    filter: brightness(0) invert(1);
  }
}

.circular-menu.active .menu-item {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.circular-menu.active .menu-item:nth-child(1) {
  transform: translate3d(1em, -7em, 0);
}

.circular-menu.active .menu-item:nth-child(2) {
  transform: translate3d(-3.5em, -6.3em, 0);
}

.circular-menu.active .menu-item:nth-child(3) {
  transform: translate3d(-6.5em, -3.2em, 0);
}

.circular-menu.active .menu-item:nth-child(4) {
  transform: translate3d(-7em, 1em, 0);
}

/**
 * The other theme for this menu
 */

.circular-menu.circular-menu-left {
  right: auto;
  left: 1em;
}

.circular-menu.circular-menu-left .floating-btn {
  background-color: hsl(217, 89%, 61%);
}

.circular-menu.circular-menu-left:after {
  background-color: hsl(217, 89%, 61%);
}

.circular-menu.circular-menu-left.active .floating-btn i {
  transform: rotate(90deg);
}

.circular-menu.circular-menu-left.active .menu-item:nth-child(1) {
  transform: translate3d(-1em, -7em, 0);
}

.circular-menu.circular-menu-left.active .menu-item:nth-child(2) {
  transform: translate3d(3.5em, -6.3em, 0);
}

.circular-menu.circular-menu-left.active .menu-item:nth-child(3) {
  transform: translate3d(6.5em, -3.2em, 0);
}

.circular-menu.circular-menu-left.active .menu-item:nth-child(4) {
  transform: translate3d(7em, 1em, 0);
}

.icon-active {
  filter: invert(98%) sepia(27%) saturate(6458%) hue-rotate(73deg) brightness(93%) contrast(72%);
}
