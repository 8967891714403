.modal-quote {
  .ant-modal {
    width: 700px !important;
    max-width: 700px !important;
    .ant-modal-content {
      width: 100% !important;
    }
  }
  .header-quote {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    .title {
      font-size: 22px;
      font-weight: 600;
      color: #0188f7;
    }
    .close-quote {
      cursor: pointer;
    }
  }
  .top-section-quote {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr;
    // grid-template-columns: 1fr;
  }
  .action-quote {
    display: flex;
    justify-content: center;
    gap: 20px;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 20px;

    button {
      border: 1px solid #d0d5dd;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      height: 42px;
      padding: 10px 18px;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      background: #475467;
      color: #ffff;
      cursor: pointer;
    }
    & :first-child {
      background-color: #ffff;
      color: #344054;
      &:hover {
        background-color: #fafafa;
      }
    }

    .dashboard-cancel-btn {
      width: 210px;
      height: 44px;
      background: #ffffff;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      color: #0188f7;
      transition: all 300ms ease;
      border: 1px solid #0188f7;

      &:hover {
        background-color: #0188f7;
        color: #ffffff;
      }
    }

    .dashboard-confirm-btn {
      width: 210px;
      height: 44px;
      background: #0188f7;
      border: 1px solid #0188f7;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      color: #ffffff;
      transition: all 300ms ease;

      &:hover {
        background: #0188f7;
        filter: brightness(90%);
      }
    }
  }
  .quote-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
}
