.ProfileV2-page-col-2-form-org-domain {
  border-radius: 20px;
  background: #fff;
  padding: 32px;

  .ProfileV2-page-col-2-form-org-domain-title {
    margin-bottom: 32px;
    color: #177c9a;
    text-align: center;
    font-size: 16.144px;
    font-style: normal;
    font-weight: 600;
    line-height: 25.113px; /* 155.556% */
  }

  .ant-steps {
    margin-bottom: 22px;

    .ant-steps-item-icon {
      // width: 35px;
      // height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .ant-steps-item-finish {
      .ant-steps-item-icon {
        background-color: #1777ff;

        & > span {
          color: #1777ff;
          background-color: #fff;
          border-radius: 50%;
          padding: 1px;
        }
      }
    }

    .ant-steps-item-title {
      color: #a3b6da !important;
      font-size: 12.556px;
      font-style: normal;
      font-weight: 500;
      line-height: 17.938px; /* 142.857% */
    }

    .ant-steps-item-description {
      color: #344054;
      font-size: 12.556px;
      font-style: normal;
      font-weight: 500;
      line-height: 17.938px; /* 142.857% */
    }
  }

  .ProfileV2-page-col-2-form-input-width {
    width: 100%;

    .ProfileV2-page-col-2-label {
      color: #344054;
      font-size: 12.556px;
      font-style: normal;
      font-weight: 500;
      line-height: 17.938px; /* 142.857% */
    }

    .personal-info-input {
      padding: 12px !important;
      color: #344054;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 21.525px; /* 143.501% */
    }

    .personal-info-input-disabled {
      color: #344054;
      border-radius: 7.175px;
      border: 0.897px solid #d0d5dd;
      background: #eff3fb !important;
    }

    .s_input {
      & > label {
        color: #344054;
        font-size: 12.556px;
        font-style: normal;
        font-weight: 500;
        line-height: 17.938px; /* 142.857% */
      }

      .ant-input-affix-wrapper {
        border: 0.897px solid #d0d5dd;
        background: #fff;
        border-radius: 7.175px;
        height: 48px;

        & > input {
          color: #344054;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 21.525px;
        }
      }

      .error_txt {
        margin-top: 4px;
      }
    }
  }

  .ant-btn {
    border-radius: 6.345px;
    border: 1px solid #0188f7;
    background: #d7edff;
    box-shadow: 0px 0.79309px 1.58618px 0px rgba(16, 24, 40, 0.05);
    color: #0188f7;
    font-size: 12.689px;
    font-style: normal;
    font-weight: 600;
    line-height: 19.034px; /* 150% */

    &:hover {
      background: #0188f7;
      color: #fff;
      transition: 0.5s;
    }
  }

  .ProfileV2-page-col-2-form-alert-container {
    margin-top: 32px;
    display: flex;
    justify-content: center;
    align-items: center;

    .ProfileV2-page-col-2-form-alert {
      width: fit-content;
    }
  }
}
