.config-versions {
    padding: 30px 50px;

    label {
        font-weight: 400;
        font-size: 14px;
        color: #101828;
    }

    .title {
        color: #0088F7;
        font-size: 26px;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 20px;
    }
}