.quota-managment {
  overflow: auto;
  padding: 34px 52px;
  padding-bottom: 15px;

  &-section1 {
    display: flex;
    column-gap: 62px;
    @media only screen and (max-width: 1400px) {
      flex-direction: column-reverse;
      row-gap: 20px;
    }
  }

  &-col1 {
    flex: 1;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
      width: 0;
      height: 0;
    }
    .upgrade-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 14px;
      p {
        color: var(--Primary-Colors-Primary-800, #177c9a);
        font-size: 16.144px;
        font-style: normal;
        font-weight: 600;
        line-height: 25.113px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .upgrade-button {
        border-radius: 8px;
        border: 0.941px solid var(--Prim-Blue, #0188f7);
        background: var(--Base-White, #fff);
        box-shadow: 0px 0.941px 1.881px 0px rgba(16, 24, 40, 0.05);
        color: var(--Prim-Blue, #0188f7);
        font-size: 15.051px;
        font-style: normal;
        font-weight: 600;
        line-height: 22.577px;
        width: 200.141px;
        padding: 9.407px 16.933px;
        text-align: center;
        cursor: pointer;
      }
    }
    .plan-resources-statistics {
      border-radius: 10px;
      border: 1px solid #d0eef1;
      margin-top: 45px;
      padding: 13px 16px;
      overflow: auto;
      &-col {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 40px;
        &:first-child {
          margin-bottom: 110px;
          @media only screen and (max-width: 1400px) {
            margin-bottom: 40px;
          }
        }
      }
    }
  }
  &-col2 {
    width: 400px;
    @media only screen and (min-width: 1600px) {
      width: 516px;
    }
    @media only screen and (max-width: 1400px) {
      width: 100%;
    }
    .user-list-title {
      color: var(--Primary-Colors-Primary-800, #177c9a);
      font-size: 16.144px;
      font-style: normal;
      font-weight: 600;
      line-height: 25.113px;
      margin-bottom: 46px;
      padding-left: 16px;
    }
    .user-list-container {
      border-radius: 10px;
      height: 100%;
      background: #f0fafb;
      display: flex;
      flex-direction: column;
      row-gap: 11px;
      justify-content: center;
      text-align: center;
      padding: 23px 26px;
      .title {
        color: #018bf7;
        font-size: 19px;
        font-style: normal;
        font-weight: 600;
        line-height: 26.4px;
      }
      .description {
        color: var(--font, #344054);
        text-align: center;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 26.4px;
      }
    }
  }
  &-section2 {
    margin-top: 47px;
    .section2-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 31px;
      p {
        color: var(--Primary-Colors-Primary-800, #177c9a);
        font-size: 16.144px;
        font-style: normal;
        font-weight: 600;
        line-height: 25.113px;
      }
      div {
        cursor: pointer;
        color: var(--Base-White, #fff);
        font-size: 14.35px;
        font-style: normal;
        font-weight: 600;
        line-height: 21.525px;
        border-radius: 7.175px;
        background: var(--Prim-Blue, #0188f7);
        box-shadow: 0px 0.897px 1.794px 0px rgba(16, 24, 40, 0.05);
        padding: 9px 16px;
        width: 200px;
        text-align: center;
      }
    }
  }
}
