.offers {
  padding: 35px 20px;
  overflow-x: hidden;
  padding-bottom: 70px;

  &-title {
    margin-bottom: 25px;
    p {
      color: var(--prim-blue, #0188f7);
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
    }
  }
  &-container {
    gap: 25px 50px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }
  .no-offers {
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 0.3px;
    text-transform: capitalize;
    margin-top: 70px;
    color: #a2a2a2e9;
  }
}

.offer-modal {
  .ant-modal-footer {
    text-align: center;
  }

  .ant-btn-primary {
    width: 210px;
    height: 44px;
    background: #ffffff;
    border: 1px solid #0188f7;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    color: #0188f7;
    transition: all 300ms ease;

    &:hover {
      background-color: #0188f7;
      color: #ffffff;
    }
  }

  .ant-btn-default {
    width: 210px;
    height: 44px;
    background: #0188f7;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    color: #ffffff;
    transition: all 300ms ease;
    border: 0 !important;

    &:hover {
      color: #fff;
    }
  }
}

.section4-container {
  // padding: 35px 60px 0 60px;
  height: 100%;

  // @media only screen and (max-width: 1559px) {
  //   padding: 35px 40px 0 40px;
  // }
  // @media only screen and (max-width: 602px) {
  //   padding: 35px 20px;
  // }
  .section4-container-title {
    margin: 25px 0;

    p {
      color: var(--prim-blue, #0188f7);
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
    }
  }

  .payment-shape {
    border-radius: 173.606px 33px 173.61px 35px;
    background: #edfcfe;
    width: 100%;
    height: 75%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -999;

    @media only screen and (max-width: 1024px) {
      display: none;
    }
  }

  .section4-small {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 12px;
    color: #3056d2;
  }

  .section4-title {
    font-size: 40px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 22px;
    color: #333;
    margin-bottom: 100px;

    @media only screen and (max-width: 420px) {
      font-size: 27px;
    }
  }

  .section4-description {
    color: #636363;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    max-width: 520px;
    text-align: center;
    padding: 0 12px;
  }

  .switch-plan {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    margin-top: 42px;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #333;

    .dark_mode {
      .dark_mode_label {
        width: 55px;
        height: 25px;
        position: relative;
        display: block;
        background: #ebebeb;
        border-radius: 200px;
        box-shadow:
          inset 0px 5px 15px rgba(0, 0, 0, 0.2),
          inset 0px -5px 15px rgba(255, 255, 255, 0.4);
        cursor: pointer;
        transition: 0.3s;
      }

      .dark_mode_label:after {
        content: "";
        width: 20px;
        height: 20px;
        position: absolute;
        top: 3px;
        left: 3px;
        background: linear-gradient(-90deg, #0188f7, #3056d2);
        border-radius: 180px;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
        transition: 0.3s;
      }

      .dark_mode_input {
        width: 0;
        height: 0;
        visibility: hidden;
        display: none;
      }

      .dark_mode_input:checked + .dark_mode_label {
        background: linear-gradient(-90deg, #bcb9b9, #ebebeb);
      }

      .dark_mode_input:checked + .dark_mode_label:after {
        left: 52px;
        transform: translateX(-100%);
        background: linear-gradient(90deg, #0188f7, #3056d2);
      }

      .dark_mode_label:active:after {
        width: 25px;
      }

      .dark_mode_label svg {
        position: absolute;
        width: 15px;
        top: 5px;
        left: 5px;
        z-index: 100;
      }

      .dark_mode_label svg.sun {
        left: 5px;
        fill: #fff;
        transition: 0.3s;
      }

      .dark_mode_label svg.moon {
        left: 35px;
        fill: #7e7e7e;
        transition: 0.3s;
      }

      .dark_mode_input:checked + .dark_mode_label svg.sun {
        fill: #7e7e7e;
      }

      .dark_mode_input:checked + .dark_mode_label svg.moon {
        fill: #fff;
      }
    }
  }

  position: relative;
  .section4-cards-container {
    margin-top: 62px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 28px;
    width: 90%;

    .section4-card-coontainer {
      // min-height: 920px; // TODO: remove

      width: 488px;
      height: 100%;
      padding: 40px 60px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 28px;
      border-radius: 32px;
      border: 1px solid #e4e7ec;
      background: #fff;
      box-shadow: 0px 26px 57px 0px rgba(172, 224, 231, 0.27); // TODO: ok

      .section4-card-flex {
        display: flex;
        flex-direction: column;
        gap: 28px;
        width: 100%;
      }

      .card-logo {
        width: 100%;
        object-fit: contain;
      }

      .card-title {
        color: #0188f7;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
      }

      .card-subtitle {
        color: #02c3ee;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
      }

      .card-price {
        color: #344054;
        font-size: 40px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: center;
      }

      .card-subprice {
        color: #9a9a9a;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
      }

      .section4-price-btn {
        margin-top: 12px;
        width: 100%;
        height: 44px;
        background-color: transparent;
        color: white;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        border: 1px solid #0188f7;
        background-color: #0188f7;
        border-radius: 9px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        position: relative;
        overflow: hidden;

        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: -100%;
          width: 100%;
          height: 100%;
          background: linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.2), transparent);
          transition: all 650ms;
        }

        &:hover:before {
          left: 100%;
        }
      }

      .card-info {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;

        .card-info-item-empty {
          min-height: 62px;
        }

        .card-info-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 20px;
          border-bottom: 1px solid #e4e7ec;
          padding: 10.51px 2px 12.51px 2px;

          @media only screen and (max-width: 320px) {
            flex-direction: column;
          }

          .card-info-item-content {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;

            .card-info-item-content-logo {
              object-fit: contain;
            }

            .card-info-item-content-title {
              color: #344054;
              font-size: 16.093px;
              font-style: normal;
              font-weight: 400;
              line-height: 29.259px; /* 181.818% */
            }
          }

          .card-info-item-value {
            color: #344054;
            font-size: 17.09px;
            font-style: normal;
            font-weight: 600;
            line-height: 29.259px; /* 171.206% */

            span {
              color: #344054;
              font-size: 12.09px;
              font-style: normal;
              font-weight: 300;
              line-height: 29.259px;
              padding-left: 1px;
            }
          }

          .card-info-item-value-active {
            color: #0188f7;
            font-size: 17.09px;
            font-style: normal;
            font-weight: 300;
            line-height: 26.3px; /* 153.891% */
          }
        }
      }
    }
  }

  .section4-price-btn-container {
    margin-top: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .section4-price-btn {
      width: 355px;
      height: 44px;
      background-color: transparent;
      color: white;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      border: 1px solid #0188f7;
      background-color: #0188f7;
      border-radius: 9px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      position: relative;
      overflow: hidden;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.2), transparent);
        transition: all 650ms;
      }

      &:hover:before {
        left: 100%;
      }

      @media only screen and (max-width: 720px) {
        width: 200px;
      }
    }
  }

  .container-offer {
    width: 100%;
    // display: flex;
    // align-items: stretch;
    // flex-wrap: wrap;
    // justify-content: center;
    // gap: 28px;
    // width: 90%;
    overflow-x: hidden;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(3, 1fr);

    padding: 35px 35px 25px 35px;

    @media only screen and (max-width: 1559px) {
      padding: 25px;
      grid-template-columns: repeat(2, 1fr);
    }
    @media only screen and (max-width: 1459px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media only screen and (max-width: 1359px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media only screen and (max-width: 802px) {
      padding: 30px 20px;
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .section4-card-coontainer {
    width: 488px;
    // height: 100%;
    padding: 40px 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 28px;
    border-radius: 32px;
    border: 1px solid #e4e7ec;
    background: #fff;
    box-shadow: 0px 26px 57px 0px rgba(172, 224, 231, 0.27);

    .section4-card-flex {
      display: flex;
      flex-direction: column;
      gap: 28px;
      width: 100%;
    }

    .navbar-auth-btn-inactive {
      text-align: center;
      cursor: pointer;
      border-radius: 8px;
      width: 154px;
      height: 44px;
      padding: 10px;
      border: 1px solid #0188f7;
      color: #0188f7;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */

      color: #ffffff;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

      background: #0188f7;
      transition: all 300ms ease;
      border: 1px solid #0188f7 !important;

      position: relative;
      overflow: hidden;
      width: 100%;

      @media only screen and (max-width: 1220px) {
        padding: 10px 22px;
      }

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, transparent, rgba(40, 158, 255, 0.1), transparent);
        transition: all 650ms;
      }

      &:hover:before {
        left: 100%;
      }
    }

    .current-plan-active-btn {
      background-color: #fff;
      color: #0188f7;

      &:before {
        background: linear-gradient(0deg, transparent, rgba(255, 255, 255, 0.1), transparent);
      }
    }

    .card-logo {
      width: 100%;
      object-fit: contain;
    }

    .card-title {
      color: #0188f7;
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: center;
    }

    .card-subtitle {
      color: #02c3ee;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: center;
    }

    .card-price {
      color: #344054;
      font-size: 40px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-align: center;
    }

    .card-subprice {
      color: #9a9a9a;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: center;
    }

    .section4-price-btn {
      margin-top: 12px;
      width: 100%;
      height: 44px;
      background-color: transparent;
      color: white;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      border: 1px solid #0188f7;
      background-color: #0188f7;
      border-radius: 9px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      position: relative;
      overflow: hidden;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.2), transparent);
        transition: all 650ms;
      }

      &:hover:before {
        left: 100%;
      }
    }

    .card-info {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .card-info-item-empty {
        min-height: 62px;
      }

      .card-info-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        border-bottom: 1px solid #e4e7ec;
        padding: 10.51px 2px 12.51px 2px;

        @media only screen and (max-width: 320px) {
          flex-direction: column;
        }

        .card-info-item-content {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 20px;

          .card-info-item-content-logo {
            object-fit: contain;
          }

          .card-info-item-content-title {
            color: #344054;
            font-size: 16.093px;
            font-style: normal;
            font-weight: 400;
            line-height: 29.259px; /* 181.818% */
          }
        }

        .card-info-item-value {
          color: #344054;
          font-size: 17.09px;
          font-style: normal;
          font-weight: 600;
          line-height: 29.259px; /* 171.206% */

          span {
            color: #344054;
            font-size: 12.09px;
            font-style: normal;
            font-weight: 300;
            line-height: 29.259px;
            padding-left: 1px;
          }
        }

        .card-info-item-value-active {
          color: #0188f7;
          font-size: 17.09px;
          font-style: normal;
          font-weight: 300;
          line-height: 26.3px; /* 153.891% */
        }
      }
    }
  }
}
