.view_tabs_notif {
  .ant-tabs-nav {
    margin: 0 !important;
    // height: 60px;
    padding: 5px 16px 0px;

    .ant-tabs-nav-wrap {
      padding: 0 10px;
      .ant-tabs-nav-list {
        .ant-tabs-tab {
          // margin-left: -10px;
        }
        .ant-tabs-tab-btn {
          font-weight: 500;
          color: rgb(99, 115, 129);
          font-family: "Poppins";
          font-style: normal;
          box-shadow: inset;
          line-height: 1.25;
          font-size: 0.875rem;
          // &:hover {
          //   font-weight: 400;
          // }
        }
        .ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            font-weight: 600;
          }
          //   color: red !important;
        }
      }
      .ant-tabs-ink-bar {
        height: 2px;
        bottom: 0px;
        width: 100%;
        transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        background-color: rgb(33, 43, 54);
      }
    }
  }
  .tab_notif_item {
    display: flex;
    align-items: center;
    gap: 10px;
    .length_tab {
      height: 24px;
      min-width: 24px;
      line-height: 0;
      border-radius: 6px;
      cursor: default;
      -webkit-box-align: center;
      align-items: center;
      white-space: nowrap;
      display: inline-flex;
      -webkit-box-pack: center;
      justify-content: center;
      text-transform: capitalize;
      padding: 0px 6px;
      font-size: 0.75rem;
      font-weight: 700;
      transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      color: rgb(255, 255, 255);
      background-color: rgb(33, 43, 54);
    }

    .unread_notif {
      // background-color: rgb(0, 184, 217);
      background-color: rgb(235, 30, 30);
    }
    .archived_notif {
      color: rgb(17, 141, 87);
      background-color: rgba(34, 197, 94, 0.16);
    }
  }
}
