.snapshot-modal {
  .snap-permissions {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > p {
      color: #000;
      font-family: Poppins;
      font-size: 13.76px;
      font-style: normal;
      font-weight: 400;
      line-height: 26.822px; /* 194.928% */
    }
    span.switch {
      border-radius: 8px;
      background: #e5f4fd;
      padding: 6px 14px;
      display: flex;
      align-items: center;
      column-gap: 47px;
      p {
        color: #000;
        font-family: Poppins;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 176.471% */
      }
    }
  }
  .s_input {
    display: block;
    margin-top: 30px !important;
  }
  .confirmation {
    margin-top: 48px !important;
    button {
      flex: 1;
      padding: 10px !important;
      font-size: 16px !important;
      font-weight: 600 !important;
    }
  }
  .ta-modal-content-scroll {
    & > div:not(:last-child) {
      margin-bottom: 34px;
    }
  }
}
