.user-quote {
  &-title {
    color: var(--Gray-700, #344054);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
  .user-quote-search {
    margin: 10px 0;
    .ant-select {
      width: 100%;
      border-radius: 8px;
      border: 1px solid var(--Gray-300, #d0d5dd);
      background: var(--Base-White, #fff);
      padding: 5px 14px;
      .ant-select-selector {
        padding: 0;
      }
    }
  }
  .quote-setting {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    margin-top: 22px;
  }
  .selected-user {
    border-radius: 27.193px;
    background: var(--Primary-Colors-Gray-100, #f2f4f7);
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 8px;
    padding: 3.5px 8px 3.5px 5px;
    width: fit-content;
    color: var(--Primary-Colors-Gray-900, #101828);
    font-size: 12.281px;
    font-style: normal;
    font-weight: 500;
    line-height: 17.544px;
    text-transform: capitalize;
  }
}
