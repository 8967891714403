.card-plan {
  box-shadow: rgba(145, 158, 171, 0.24) -24px 24px 72px -8px;
  background-color: rgb(255, 255, 255);
  color: rgb(33, 43, 54);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-image: none;
  overflow: hidden;
  position: relative;
  // box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
  border-radius: 16px;
  z-index: 0;
  padding: 20px 40px 30px 40px;
  margin-bottom: 30px;

  //   background-color: red;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  max-height: 100%;
  @media only screen and (max-width: 1520px) {
    padding: 20px 30px;
  }
  .top-section {
    .popular {
      height: 24px;
      min-width: 24px;
      line-height: 0;
      border-radius: 6px;
      cursor: default;
      -webkit-box-align: center;
      align-items: center;
      white-space: nowrap;
      display: inline-flex;
      -webkit-box-pack: center;
      justify-content: center;
      text-transform: capitalize;
      padding: 0px 6px;
      font-size: 0.75rem;
      font-weight: 700;
      transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      color: rgb(0, 108, 156);
      background-color: rgba(0, 184, 217, 0.16);
      position: absolute;
      top: 24px;
      left: 32px;
    }
  }
  .title-plan {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px !important;
    .logo-plan {
      width: 60px;
    }
    p {
      margin: 0px 0px 16px;
      font-weight: 600;
      line-height: 1.5;
      font-size: 1.25rem;
      color: #0188f7;
    }
  }
  .pricing {
    margin-bottom: 30px;
    .price {
      margin: 0;
      margin-top: 20px;

      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: space-between;
      width: 100%;
      // span {
      //   margin: 0px;
      //   font-weight: 600;
      //   line-height: 1.5;
      //   font-size: 1.125rem;
      //   color: rgb(145, 158, 171);
      // }
      .price-toggles {
        display: flex;
        align-items: center;
        gap: 10px;
        .method-price {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-left: 12px;
          padding-right: 12px;
          height: 30px;
          border-radius: 8px;
          position: relative;
          font-weight: 600;
          line-height: 1.57143;
          font-size: 0.775rem;
          border: 1px solid rgba(145, 158, 171, 0.24);
          color: rgb(145, 158, 171);
        }
        .active-method {
          color: #0088f7;
          border: 1px solid #0088f7;
        }
      }
      .price-value {
        font-weight: 700;
        line-height: 1.5;
        font-size: 1.5rem;
        display: flex;
        align-items: flex-end;
        gap: 3px;
      }

      .method-display {
        font-size: 12px;
      }
    }
    .price-value-free {
      font-weight: 600;
      line-height: 1.5;
      font-size: 1.1rem;
      align-items: flex-end;
      display: flex;
      gap: 3px;
      span {
        color: #0088f7;
      }
    }
    .without-card {
      color: #0088f7;
    }
  }
  .description-plan {
    margin: 24px 0px 0px;
    line-height: 1.57143;
    font-size: 0.875rem;
    font-weight: 400;
    color: rgb(99, 115, 129);
    margin-bottom: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Number of lines to show */
    -webkit-box-orient: vertical;
  }
  .features-plan {
    margin-bottom: 20px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 15px;
  }

  .action-plan {
    margin-top: auto;
    button {
      justify-content: center;
      position: relative;
      box-sizing: border-box;
      -webkit-tap-highlight-color: transparent;
      background-color: transparent;
      outline: 0;
      border: 0;
      margin: 0;
      border-radius: 0;
      padding: 0;
      cursor: pointer;
      color: inherit;
      font-weight: 600;
      line-height: 1.7142857142857142;
      font-size: 0.9375rem;
      text-transform: capitalize;
      min-width: 64px;
      padding: 8px 22px;
      border-radius: 8px;
      color: inherit;
      background-color: #dfe3e8;
      box-shadow:
        0px 3px 1px -2px rgba(145, 158, 171, 0.2),
        0px 2px 2px 0px rgba(145, 158, 171, 0.14),
        0px 1px 5px 0px rgba(145, 158, 171, 0.12);
      border-color: currentColor;
      width: 100%;
      box-shadow: none;
      color: #ffffff;
      background-color: #0188f7;
      float: inline-end;
    }
  }

  .current-plan-active-btn {
    background-color: #fff !important;
    color: #0188f7 !important;
    border: 1px solid #0188f7 !important;

    &:before {
      background: linear-gradient(
        0deg,
        transparent,
        rgba(255, 255, 255, 0.1),
        transparent
      ) !important;
    }
  }
  .cpu-hours {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  .toggle-cpu {
    border-radius: 37.654px;
    background: #e1f2ff;
    display: flex;
    padding: 6.695px 10.729px;
    justify-content: center;
    align-items: center;
    gap: 6.695px;
    color: #82a8c8;
    font-size: 11.382px;
    font-style: normal;
    font-weight: 400;
    line-height: 24.612px; /* 216.244% */
    width: fit-content;
    cursor: pointer;
  }
  .selected-cpu {
    color: #fff;
    border-radius: 32.806px;
    background: var(--Prim-Blue, #0188f7);
    box-shadow: 0px 3.012px 3.314px 0px rgba(186, 225, 249, 0.9);
  }
}

.selected-card {
  border: 1px solid var(--Prim-Blue, #0188f7);
}
