.tags {
  display: flex;
  gap: 10px;
  // flex-direction: column;
  flex-direction: column;
  width: 100%;
  .tagInput {
    display: flex;
    align-items: center;
    // flex: auto;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
    margin-top: -8px;
    position: relative;
  }
  .input_tag {
    height: 44px;
    background: #ffffff;
    border: 1px solid #d0d5dd;
    border-radius: 8px;

    &::placeholder {
      font-weight: 300;
      font-size: 15px;
      color: #d0d5dd;
    }
  }
  .add_url_btn {
    display: flex;
    width: 56.835px;
    height: 35px;
    padding: 0px 20px;
    justify-content: center;
    align-items: center;
    gap: 14px;
    flex-shrink: 0;
    border-radius: 7px;
    background: #3288f7;
    border: none;
    cursor: pointer;
    position: absolute;
    right: 8px;
    color: #ffffff;
  }
  .clear_btn {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .tags_items {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    .tag_url {
      display: flex;
      padding: 4.408px 13.223px;
      align-items: flex-start;
      gap: 12.342px;
      border-radius: 7.052px;
      border: 0.882px solid var(--stroke, #e4e7ec);
      background: #f3f3f3;
      display: flex;
      align-items: center;
      .ant-tag-close-icon {
        display: flex;
        align-items: center;
      }
    }
  }
  .btn_section {
    display: flex;
    gap: 10px;
  }
}
