.security {
  .security-container {
    margin-top: 42px;

    form {
      display: flex;
      flex-direction: column;
      gap: 6px;
    }
  }

  .security-container-input {
    margin-top: 22px;
  }

  .security-container-change-password {
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;

    button {
      width: 232px;
      height: 44px;
      background: #0188f7;
      border-radius: 8px;
      font-weight: 400;
      font-size: 13px;
      line-height: 24px;
      color: #ffffff;
      border: 0;
      cursor: pointer;
    }
  }

  .security_form {
    margin-top: 18px;
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    width: 100%;

    button {
      border: 1px solid #d0d5dd;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      height: 42px;
      padding: 10px 18px;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      background: #475467;
      color: #ffff;
      cursor: pointer;
    }
    & :first-child {
      background-color: #ffff;
      color: #344054;
      &:hover {
        background-color: #fafafa;
      }
    }

    .dashboard-cancel-btn {
      width: 210px;
      height: 44px;
      background: #ffffff;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      color: #0188f7;
      transition: all 300ms ease;
      border: 1px solid #0188f7;

      &:hover {
        background-color: #0188f7;
        color: #ffffff;
      }
    }

    .dashboard-confirm-btn {
      width: 210px;
      height: 44px;
      background: #0188f7;
      border: 1px solid #0188f7;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      color: #ffffff;
      transition: all 300ms ease;

      &:hover {
        background: #0188f7;
        filter: brightness(90%);
      }
    }
  }
}
