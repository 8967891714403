.pending-page-container {
  overflow: hidden;

  .ant-table-thead {
    .ant-table-cell {
      background-color: #f1fafd;
      color: #101828;
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      border-radius: 0 !important;
    }
  }

  .ant-table-content {
    overflow-x: auto;
  }
}

.pending-users-text {
  font-size: 12px;
  font-weight: 400;
  max-width: 120px;
  color: #475467;
  white-space: nowrap;
}
