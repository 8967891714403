.controls-attach-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dfe6ec;
  height: 42px;
  margin-bottom: 10px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  svg {
    width: 16px;
    height: 16px;
    fill: #6080a0;
  }
  .userback-controls-item {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    #feedback-file {
      display: none;
    }
  }
  .userback-controls-item:nth-child(2) {
    border-left: 1px solid #dfe6ec;
    border-right: 1px solid #dfe6ec;
  }
}
