.ProfileV2-page-col-2-form-security {
  width: 100%;
  display: grid;
  gap: 12px;

  .s_input {
    & > label {
      color: #344054;
      font-size: 12.556px;
      font-style: normal;
      font-weight: 500;
      line-height: 17.938px; /* 142.857% */
    }

    .ant-input-affix-wrapper {
      border: 0.897px solid #d0d5dd;
      background: #fff;
      border-radius: 7.175px;
      height: 48px;

      & > input {
        color: #344054;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 21.525px;
      }
    }

    .error_txt {
      margin-top: 4px;
    }
  }

  .ProfileV2-page-col-2-form-btn {
    width: 100%;
    margin-top: 12px;

    .ProfileV2-page-col-2-form-btn-confirm {
      cursor: pointer;
      width: 180px;
      height: 44px;
      background: #0188f7;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      color: #fff;
      transition: all 300ms ease;
      border: 1px solid #0188f7;
      box-shadow: 0px 0.89688px 1.79376px 0px rgba(16, 24, 40, 0.05);

      &:hover {
        background-color: #0b7ad5;
        color: #ffffff;
      }
    }
  }

  .ProfileV2-page-col-2-form-security-stront-password {
    margin-bottom: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 22px;

    & > span {
      width: 100%;
      height: 4px;
      background: #d9d9d9;
    }

    & > small {
      font-size: 12.556px;
      font-style: normal;
      font-weight: 500;
      line-height: 17.938px; /* 142.857% */
    }
  }
}
